import { createGlobalStyle, css, styled } from "styled-components";
import { CssMixin } from "theme";

export type DrawerPosition = "right" | "left";

export type PaddingSize = "none" | "md" | "lg" | "xl";

export const paddingSize: Record<PaddingSize, number> = {
  none: 0,
  md: 20,
  lg: 32,
  xl: 40,
};

interface DrawerPositionMixinProps {
  $opened: boolean;
}

const Right = css<DrawerPositionMixinProps>`
  top: 0;
  right: 0;

  transform: ${({ $opened }) =>
    $opened ? "translateX(0)" : "translateX(100%)"};
`;

const Left = css<DrawerPositionMixinProps>`
  top: 0;
  left: 0;

  transform: ${({ $opened }) =>
    $opened ? "translateX(0)" : "translateX(-100%)"};
`;

const DRAWER_POSITIONS: Record<
  DrawerPosition,
  CssMixin<DrawerPositionMixinProps>
> = {
  left: Left,
  right: Right,
};

interface RootProps extends DrawerPositionMixinProps {
  $position: DrawerPosition;
  $width: string;
  $padding?: PaddingSize;
}

export const Root = styled.div<RootProps>`
  padding: ${({ $padding = "md", theme: { rem } }) =>
    rem(paddingSize[$padding])};

  position: fixed;
  z-index: 101;
  width: ${({ $width }) => $width};
  height: 100%;

  transition: transform 0.2s ease-out;

  background-color: ${({ theme }) => theme.colors.boxBg};
  border: 1px solid ${({ theme }) => theme.colors.gray10};

  ${({ $position }) => DRAWER_POSITIONS[$position]}
  ${({ $padding = "md", theme }) => theme.mediaWidth.medium`
  padding: ${
    $padding === "lg" ? theme.rem(24) : theme.rem(paddingSize[$padding])
  };
  border-radius: ${theme.rem(10)};
`};
`;

export const Backdrop = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0 0 0 / 50%);
`;

export const NoOverflow = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

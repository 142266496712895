import { PropsWithChildren, useEffect, useRef } from "react";

import {
  Content,
  DropdownHorAlign,
  DropdownVerAlign,
  Root,
  Title,
} from "./styles";

export interface DropdownProps {
  title: React.ReactNode;
  opened: boolean;
  onClose: (triggeredBy: "contentClick" | "outsideClick") => void;
  opens?: DropdownHorAlign;
  position?: DropdownVerAlign;
  contentClick?: "close" | "nothing";
  width?: string;
  contentWidth?: string;
}

export function Dropdown({
  title,
  children,
  opened,
  onClose,
  position = "bottom",
  opens = "left",
  contentClick = "close",
  width = "auto",
  contentWidth,
}: PropsWithChildren<DropdownProps>) {
  const dropdown = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!dropdown.current) return;
      if (!event.target || !(event.target instanceof Element)) return;

      if (!dropdown.current.contains(event.target)) onClose("outsideClick");
    };

    if (opened) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return function unsubscribeClick() {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose, opened]);

  const handleContentClick =
    contentClick === "close"
      ? () => {
          onClose("contentClick");
        }
      : undefined;

  return (
    <Root $width={width} tabIndex={-1} ref={dropdown}>
      <Title $width={width}>{title}</Title>

      <Content
        $width={contentWidth}
        data-opened={opened}
        onClick={handleContentClick}
        $opens={opens}
        $position={position}
      >
        {children}
      </Content>
    </Root>
  );
}

import { Property } from "csstype";
import { styled } from "styled-components";

import { Div } from "../Div";

export interface ColProps {
  $noGutters?: boolean;
  $align?: Property.AlignItems;
  $justify?: Property.JustifyContent;
  $wrap?: boolean;

  $gap?: number;
}

export const Col = styled(Div)<ColProps>`
  ${({ $noGutters = false }) => ($noGutters ? "" : `padding-right: 16px;`)};
  ${({ $noGutters = false }) => ($noGutters ? "" : `padding-left: 16px;`)};

  ${({ $gap, theme }) => ($gap ? `gap:  ${theme.size($gap)};` : "")};

  display: flex;
  flex-direction: column;
  flex-wrap: ${({ $wrap = true }) => ($wrap ? "wrap" : "nowrap")};
  justify-content: ${({ $justify }) => $justify};
  align-items: ${({ $align }) => $align};
`;

import { css, styled } from "styled-components";
import { CssMixin } from "theme";

export const iconSizes: Record<Size, { arrow: number }> = {
  sm: { arrow: 12 },
  md: { arrow: 16 },
};

export type DropdownTheme = "filled" | "empty" | "outlined" | "dashed";

export type Size = "md" | "sm";

const SizeMd = css`
  padding: 13px 12px 13px 16px;
  font-size: ${({ theme }) => theme.rem(14)};
  line-height: ${({ theme }) => theme.rem(17)};
  border-radius: 12px;
`;

const SizeSm = css`
  padding: 7px 10px 7px 12px;
  font-size: ${({ theme }) => theme.rem(14)};
  line-height: ${({ theme }) => theme.rem(17)};
  border-radius: 8px;
`;

const SIZE_THEME: Record<Size, CssMixin> = {
  md: SizeMd,
  sm: SizeSm,
};

const DropdownButtonFilled = css`
  background-color: ${({ theme }) => theme.colors.gray60};
  border: none;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray80};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.textSecondary};
    background-color: ${({ theme }) => theme.colors.gray20};
    cursor: unset;
  }
`;

const DropdownButtonOutlined = css`
  background: ${({ theme }) => theme.colors.transparent};
  border: 1px solid ${({ theme }) => theme.colors.gray40};

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray60};
    border-color: ${({ theme }) => theme.colors.gray60};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.textSecondary};
    background-color: ${({ theme }) => theme.colors.gray20};
    border-color: ${({ theme }) => theme.colors.gray30};
    cursor: unset;
  }
`;

const DropdownButtonEmpty = css`
  padding: 0;
  background: transparent;
  border: none;
  box-shadow: none;

  &:hover {
    color: ${({ theme }) => theme.colors.textSecondary2};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.textSecondary};
    cursor: unset;
  }
`;

const DropdownButtonDashed = css`
  padding: 0;
  background: transparent;
  border: none;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.textSecondary2};
  border-radius: 0;
  box-shadow: none;

  &:hover {
    color: ${({ theme }) => theme.colors.textSecondary2};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.textSecondary};
    cursor: unset;
  }
`;

const THEME: Record<DropdownTheme, CssMixin> = {
  filled: DropdownButtonFilled,
  empty: DropdownButtonEmpty,
  outlined: DropdownButtonOutlined,
  dashed: DropdownButtonDashed,
};

export interface DropdownButtonProps {
  $theme: DropdownTheme;
  $size: Size;
}

export const DropdownButton = styled.button<DropdownButtonProps>`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  color: ${({ theme }) => theme.colors.textPrimary};

  box-shadow: 0 2px 2px rgba(0 0 0 / 25%);

  ${({ $size }) => SIZE_THEME[$size]};
  ${({ $theme }) => THEME[$theme]}
`;

interface DropdownMenuProps {
  $minWidth: string;
}

export const DropdownMenu = styled.ul<DropdownMenuProps>`
  min-width: ${({ $minWidth }) => $minWidth};

  margin: 0px !important;
  padding: ${({ theme }) => theme.rem(0)};

  text-align: left;

  max-height: 400px;

  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar-track {
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.transparent};
  }
`;

export const DropdownBox = styled.div`
  z-index: 2;
  margin-top: 8px !important;
  padding: ${({ theme }) => theme.rem(4)};
  padding-top: ${({ theme }) => theme.rem(4)};
  padding-bottom: ${({ theme }) => theme.rem(4)};

  background-color: ${({ theme }) => theme.colors.gray40};
  border-radius: 12px;
  box-shadow: 0 2px 16px rgba(0 0 0 / 60%);
`;

interface IconWrapProps {
  $opened: boolean;
}

export const IconWrap = styled.span<IconWrapProps>`
  margin-left: 10px;
  display: flex;
  width: min-content;

  justify-content: flex-end;
  ${({ theme }) => theme.flexCol(true)}

  svg:first-child {
    transition: transform ease 0.1s;
    transform: rotate(${({ $opened }) => ($opened ? 180 : 0)}deg);
  }
`;

export const DropdownText = styled.li`
  font-size: ${({ theme }) => theme.rem(14)};
  line-height: ${({ theme }) => theme.rem(17)};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.textPrimary};
  background-color: transparent;
  display: span;
  list-style-type: none;
`;

export const DropdownItem = styled(DropdownText)`
  padding: ${({ theme }) => theme.rem(12)};
  display: flex;
  width: 100%;

  align-items: center;
  white-space: normal;
  background-color: transparent;
  border: 0;
  border-radius: 8px;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.gray80};
  }
`;

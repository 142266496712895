import { GearboxState } from "@gearbox-protocol/sdk-gov";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "store";
import { json_parse, json_stringify } from "utils/bigint-serializer";
import { createInitialAsyncState } from "utils/store";

const initialState = createInitialAsyncState<GearboxState>();

export const getGearboxState = createAsyncThunk<GearboxState>(
  "gearboxState/getGearboxState",
  async () => {
    const { data } = await axios.get<GearboxState>(
      "http://localhost:8000/state",
    );

    return data;
  },
);

const gearboxStateSlice = createSlice({
  name: "gearboxState",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getGearboxState.pending, state => {
        state.status = "loading";
      })
      .addCase(getGearboxState.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(getGearboxState.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default gearboxStateSlice.reducer;

export const selectGearboxState = createSelector(
  [(state: RootState) => state.gearboxState],
  gearboxState => ({
    ...gearboxState,
    data: gearboxState.data
      ? (json_parse(json_stringify(gearboxState.data)) as GearboxState)
      : undefined,
  }),
);

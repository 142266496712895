export function GearIcon() {
  return (
    <svg
      width="50"
      height="48"
      viewBox="0 0 50 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.5002 39.9241C19.588 39.9241 14.5508 36.2001 12.6304 30.9818C14.7675 35.9545 20.03 39.5226 25.5002 39.5226C30.97 39.5226 36.2724 35.9945 38.3693 30.9818C36.4495 36.2001 31.4122 39.9241 25.5002 39.9241ZM38.706 21.1551C38.0669 19.6201 38.724 17.8734 40.1757 17.1179C38.8164 15.0301 37.0504 13.3039 35.0292 12.0006C34.4711 13.0616 33.3745 13.6952 32.2204 13.7045C34.8952 16.1028 38.5342 20.0333 38.5342 25.9311C38.5342 33.0906 32.6985 38.8945 25.5002 38.8945C18.3012 38.8945 12.4658 33.0906 12.4658 25.9311C12.4658 20.0333 16.1052 16.1028 18.7797 13.7045C17.6252 13.6952 16.5286 13.0607 15.9703 12C13.871 13.352 12.1354 15.1081 10.8255 17.1182C12.2768 17.8737 12.933 19.6209 12.294 21.1551C11.6552 22.6898 9.94932 23.4623 8.38571 22.9769C7.88237 25.3193 7.86119 27.7817 8.384 30.2138C9.94788 29.7273 11.6549 30.5009 12.294 32.0358C12.9333 33.5709 12.2762 35.3172 10.8243 36.073C12.184 38.1609 13.9495 39.8871 15.9706 41.1899C16.7305 39.7466 18.487 39.0937 20.03 39.7291C21.5727 40.3649 22.3497 42.0615 21.8616 43.6165C24.2169 44.1171 26.6931 44.138 29.1385 43.618C28.6491 42.063 29.4271 40.3651 30.9702 39.7293C32.5138 39.0935 34.2699 39.7473 35.0297 41.1912C37.129 39.8389 38.8649 38.0829 40.1746 36.073C38.7236 35.317 38.0669 33.57 38.706 32.0358C39.3448 30.5013 41.051 29.7284 42.6143 30.2138C43.1176 27.8718 43.1389 25.409 42.616 22.9769C41.0518 23.4634 39.3451 22.69 38.706 21.1551Z"
        fill="white"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.479 9C18.7524 12.0275 19.7926 14.2686 20.4283 16.5586C22.8269 25.1977 13.0361 27.3848 16.8421 18.2392C15.3353 20.6962 14 22.5721 14 26.8662C14 31.2156 16.3031 35.0069 19.7138 37C18.9449 32.2322 23.2084 31.4598 25.208 27.8491C28.8366 21.2956 18.7198 15.5954 20.479 9Z"
        fill="#F17300"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.7241 12.5127C24.7023 9.17266 22.1824 5.93442 25.3715 0C23.2057 1.98148 19.0615 9.5148 22.506 14.5278C25.5607 18.9737 28.191 22.7752 26.5861 27.2641C25.1684 31.2286 19.8894 33.1124 21.2102 37.4346C28.4379 39.8259 36 34.3411 36 26.456C36 19.3265 29.2352 14.183 27.7241 12.5127Z"
        fill="#E03597"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.4806 3C25.1256 7.71172 27.0671 8.88211 30.9785 13C31.3518 9.3017 26.7029 7.23644 29.4806 3Z"
        fill="#F17300"
      ></path>
    </svg>
  );
}

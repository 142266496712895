import { Col, Loader, Row, StyledButton } from "components/elements";
import { selectGearboxState } from "features/gearboxState/gearboxStateSlice";
import { useAppSelector } from "hooks";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { NewTxFormFormValues } from "types/transactions";

import { CmField } from "../NewTxFormFields/CmField";
import { NumberField } from "../NewTxFormFields/NumberField";
import { TokenField } from "../NewTxFormFields/TokenField";

export function AddCollateralTokenModule() {
  const { data, status } = useAppSelector(selectGearboxState);

  const {
    watch,
    formState: { errors },
  } = useFormContext<NewTxFormFormValues>();
  const watchedValue = watch();

  const disabled = useMemo(
    () =>
      !watchedValue.cmd ||
      !watchedValue.cm ||
      !watchedValue.token ||
      watchedValue.threshold === undefined,
    [
      watchedValue.cmd,
      watchedValue.cm,
      watchedValue.token,
      watchedValue.threshold,
      watchedValue.rampDuration,
      watchedValue.rampStart,
    ],
  );

  if (status !== "succeeded" || !data) {
    return (
      <Col $p={16}>
        <Loader />
      </Col>
    );
  }

  return (
    <>
      <CmField />

      <TokenField />

      <NumberField
        name="threshold"
        endingIcon="%"
        title="Liquidation Threshold"
      />

      <Row $noGutters $justify="flex-end">
        <StyledButton
          type="submit"
          disabled={disabled || Object.keys(errors).length > 0}
        >
          Add Tx
        </StyledButton>
      </Row>
    </>
  );
}

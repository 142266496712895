import { styled } from "styled-components";

export const Root = styled.div<{ $width: string }>`
  position: relative;
  width: ${({ $width }) => $width};
  ${({ theme }) => theme.flexRow.rowNoWrap}
`;

export const Title = styled.div<{ $width: string }>`
  width: ${({ $width }) => $width};
`;

export type DropdownHorAlign = "left" | "right";
export type DropdownVerAlign = "top" | "bottom";

const placementByPosition: Record<DropdownVerAlign, string> = {
  top: "bottom",
  bottom: "top",
};

export const Content = styled.div<{
  $opens: DropdownHorAlign;
  $position: DropdownVerAlign;
  $width?: string;
}>`
  position: absolute;
  ${({ $position }) => placementByPosition[$position]}: 100%;
  ${({ $opens }) => $opens}: 0;

  z-index: 101;
  width: ${({ $width }) => $width};

  opacity: 0;
  pointer-events: none;
  transition:
    transform 0.1s ease,
    opacity 0.1s;
  transform: translateY(10%);

  &[data-opened="true"] {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0%);
  }
`;

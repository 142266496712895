import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css, styled } from "styled-components";
import { Colors } from "theme";

export interface FaIconStyledProps {
  $size?: number;
  $color?: keyof Colors;
  $hoverColor?: keyof Colors;
  $disabledColor?: keyof Colors;
}

const HoverMixin = css<FaIconStyledProps>`
  fill: ${({ theme, $hoverColor }) =>
    $hoverColor ? theme.colors[$hoverColor] : "unset"};
`;

const SizeMixin = css<FaIconStyledProps>`
  width: ${({ $size }) => ($size === undefined ? "unset" : `${$size}px`)};
  height: ${({ $size }) => ($size === undefined ? "unset" : `${$size}px`)};
`;

const DisabledMixin = css<FaIconStyledProps>`
  fill: ${({ theme, $disabledColor }) =>
    $disabledColor ? theme.colors[$disabledColor] : "unset"};
`;

export const FaIconStyled = styled(FontAwesomeIcon)<FaIconStyledProps>`
  ${({ $size }) => ($size !== undefined ? SizeMixin : "")};

  path {
    fill: ${({ theme, $color }) =>
      $color ? theme.colors[$color] : "currentColor"};
  }

  &:hover {
    path {
      ${({ $hoverColor }) => ($hoverColor ? HoverMixin : "")};
    }
  }

  &[data-disabled="true"] {
    path {
      ${({ $disabledColor }) => ($disabledColor ? DisabledMixin : "")};
    }
  }
`;

export const IconStyledRoot = styled.span<FaIconStyledProps>`
  ${({ $size }) => ($size !== undefined ? SizeMixin : "")};

  path {
    fill: ${({ theme, $color }) => ($color ? theme.colors[$color] : "")};
  }

  &:hover {
    path {
      ${({ $hoverColor }) => ($hoverColor ? HoverMixin : "")};
    }
  }

  &[data-disabled="true"] {
    path {
      ${({ $disabledColor }) => ($disabledColor ? DisabledMixin : "")};
    }
  }
`;

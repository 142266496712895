import { SafeProvider } from "@safe-global/safe-apps-react-sdk";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { WagmiProvider } from "wagmi";

import App from "./App";
import { store } from "./store";
import { DefaultGlobalStyle, ThemeProvider } from "./theme";
import { wagmiConfig } from "./utils/wagmi";

const queryClient = new QueryClient();

export default function MainPage() {
  return (
    <Provider {...{ store }}>
      <QueryClientProvider client={queryClient}>
        <WagmiProvider config={wagmiConfig}>
          <SafeProvider>
            <ThemeProvider>
              <DefaultGlobalStyle />
              <App />
            </ThemeProvider>
          </SafeProvider>
        </WagmiProvider>
      </QueryClientProvider>
    </Provider>
  );
}

render(
  <React.StrictMode>
    <MainPage />
  </React.StrictMode>,
  document.getElementById("root"),
);

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ButtonHTMLAttributes } from "react";
import { styled } from "styled-components";

import { FaIconStyled } from "../FaIcon";
import { ButtonSize, StyledButton, StyledButtonProps } from "./Button";

const iconSizes: Record<ButtonSize, number> = {
  sm: 12,
  md: 16,
  lg: 20,
};

export interface IconButtonProps extends StyledButtonProps {
  icon: IconProp;
  onClick?: () => void;
}

export const IconWrap = styled.span`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export function IconButton({
  icon,
  $size = "md",
  onClick,
  ...restProps
}: IconButtonProps) {
  return (
    <StyledButton
      {...restProps}
      $size={$size}
      $sizeTheme="icon"
      onClick={onClick}
    >
      <IconWrap>
        <FaIconStyled icon={icon} $size={iconSizes[$size]} />
      </IconWrap>
    </StyledButton>
  );
}

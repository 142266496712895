import { PropsWithChildren } from "react";

import {
  Backdrop,
  DrawerPosition,
  NoOverflow,
  PaddingSize,
  Root,
} from "./styles";

export interface DrawerProps {
  opened: boolean;
  position?: DrawerPosition;
  width?: string;

  padding?: PaddingSize;
  onClose: () => void;
}

export function Drawer({
  opened,
  position = "right",
  width = "40%",

  padding = "lg",
  onClose,
  children,
}: PropsWithChildren<DrawerProps>) {
  return (
    <>
      {opened && (
        <>
          <NoOverflow />
          <Backdrop onClick={onClose} />
        </>
      )}
      <Root
        $width={width}
        $position={position}
        $opened={opened}
        $padding={padding}
      >
        {children}
      </Root>
    </>
  );
}

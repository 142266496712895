import { Col, Input, Typography } from "components/elements";
import { Controller, useFormContext } from "react-hook-form";
import {
  NewTxFormFormNumberValues,
  NewTxFormFormValues,
} from "types/transactions";

interface NumberFieldProps {
  name: keyof NewTxFormFormNumberValues;
  title: string;
  placeholder?: string;
  endingIcon?: React.ReactNode;
  validate?: (value?: number) => string | true;
}

export function NumberField({
  name,
  title,
  placeholder = "0",
  endingIcon,
  validate,
}: NumberFieldProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext<NewTxFormFormValues>();

  return (
    <Col $noGutters $gap={2} style={{}}>
      <Typography>{title}</Typography>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            required
            placeholder={placeholder}
            error={errors[name]?.message}
            type="number"
            endingIcon={endingIcon}
            step={0.01}
          />
        )}
        rules={{
          validate,
        }}
      />
    </Col>
  );
}

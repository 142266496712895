import { css, DefaultTheme } from "styled-components";

import {
  FONT_FAMILY,
  FONT_SIZE,
  MAX_COL,
  MEDIA_WIDTHS,
  SIZE_STEP,
} from "./constants";
import { BgMode, Colors, ColSize, MediaWidth, MediaWidthList } from "./types";

export function makeWidthTemplates(mediaWidth: MediaWidthList) {
  return Object.entries(mediaWidth).reduce<MediaWidth>((acc, [key, size]) => {
    const rule = (...props: Array<any>) => css`
      @media (max-width: ${size}px) {
        ${css(...(props as [any]))}
      }
    `;
    return {
      ...acc,
      [key]: rule,
    };
  }, {} as MediaWidth);
}

export const MEDIA_WIDTH_TEMPLATES = makeWidthTemplates(MEDIA_WIDTHS);

export function colors(_darkMode: boolean): Colors {
  return {
    pageBg: "#1B2135",
    boxBg: "#121624",
    modalBg: "#151B29",

    borderSecondary: "#252D42",
    borderTransparentContrast: "#3A4564",

    mainPrimary: "#C42A7C",
    mainAccent: "#E83695",

    additionalSuccess: "#92ffb0",
    additionalWarning: "#FFD25F",
    additionalAlert: "#FF5050",

    gray110: "#BBC3DD",
    gray100: "#7482A4",
    gray90: "#6B7798",
    gray80: "#616D8C",
    gray70: "#58637F",
    gray60: "#4E5973",
    gray50: "#454E67",
    gray40: "#3B445A",
    gray30: "#323A4E",
    gray20: "#283042",
    gray10: "#1F2535",

    darkSelected: "#323C53",

    primary: "rgba(0, 0, 0, 0.3)",
    primaryAltHover: "#262C3F",

    danger: "rgb(241, 5, 5)",
    dangerHover: "rgb(209, 5, 5)",

    success: "#21be4e",
    successHover: "#12b13f",

    disabled: "#272f3f",

    dropdownDarkButton: "#242B3E",
    dropdownDarkContent: "#121624",

    farmingColor: "#8540F5",

    transparent: "transparent",

    checkboxBorder: "#3E4762",
    checkboxBorderHover: "#656E88",

    textPrimary: "#fff",
    textSecondary: "#ABB2CB",
    textSecondary2: "#C3C7D4",

    textDark: "#1A2032",
    textLink: "#2172E5",

    indicatorDark: "#0f131e",

    textSuccess: "#92FFB0",

    white: "#fff",
    black: "#000",
  };
}

export const remConverter = (px: number) => px / FONT_SIZE;
export const rem = (px: number) => `${remConverter(px)}rem`;
export const size = (sizeValue: number) =>
  `${remConverter(SIZE_STEP) * sizeValue}rem`;

export const column = (s: ColSize) => {
  if (s === true) {
    return css`
      flex: 1 0;
    `;
  }
  if (s === "auto") {
    return css`
      width: auto;
      flex: 0 0 auto;
    `;
  }
  if (s === 12) {
    return css`
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
    `;
  }
  return css`
    width: ${(100 / MAX_COL) * s}%;
    flex: 0 0 auto;
  `;
};

export const sizeMixin = <T extends string | number>(
  size: T,
  record: Record<T, { size: number } | number>,
) => css`
  width: ${() => {
    const themeVariant = record[size];
    return typeof themeVariant === "number" ? themeVariant : themeVariant.size;
  }}px;
  min-width: ${() => {
    const themeVariant = record[size];
    return typeof themeVariant === "number" ? themeVariant : themeVariant.size;
  }}px;
  height: ${() => {
    const themeVariant = record[size];
    return typeof themeVariant === "number" ? themeVariant : themeVariant.size;
  }}px;
  min-height: ${() => {
    const themeVariant = record[size];
    return typeof themeVariant === "number" ? themeVariant : themeVariant.size;
  }}px;
`;

const memoHf =
  (colorsTheme: Colors) =>
  (hf: number | undefined): string | undefined => {
    if (!hf) return undefined;

    if (hf > 15000) return colorsTheme.additionalSuccess;

    if (hf < 11000) return colorsTheme.additionalAlert;

    return colorsTheme.additionalWarning;
  };

interface ThemeProps {
  darkMode: boolean;
  bgMode: BgMode;
}

export function theme({ darkMode, bgMode }: ThemeProps): DefaultTheme {
  const currentColors = colors(darkMode);
  return {
    bgMode,

    fontFamily: {
      main: FONT_FAMILY,
    },

    colors: currentColors,

    layoutBorder: "1px solid rgba(255, 255, 255, 0.07)",

    rem,
    size,

    borderRadius: {
      xs: 6,
      sm: 12,
      md: 16,
      lg: 36,
    },

    grids: {
      sm: 1,
      md: 2,
      lg: 4,
    },

    mediaWidth: MEDIA_WIDTH_TEMPLATES,

    flexRow: {
      columnWrap: css`
        display: flex;
        flex-flow: column wrap;
      `,
      columnNoWrap: css`
        display: flex;
        flex-flow: column nowrap;
      `,

      rowWrap: css`
        display: flex;
        flex-flow: row wrap;
      `,
      rowNoWrap: css`
        display: flex;
        flex-flow: row nowrap;
      `,

      centered: css`
        justify-content: center;
        align-items: center;
      `,
    },

    flexCol: column,

    hfColor: memoHf(currentColors),

    sizeMixin,
  };
}

import { PropsWithChildren, useRef } from "react";

import { DialogContainerProps } from "../DialogWindow";
import {
  DarkenDiv,
  ModalContentWrapper,
  ModalDiv,
  NoOverflow,
} from "../Modal/styles";

export interface ModalProps extends DialogContainerProps {
  show: boolean;
  zIndexBase?: number;
  xsCentered?: boolean;
  onClick?: () => void;
}

export function Modal({
  show,
  zIndexBase,
  children,
  xsCentered,
  onClick,
}: PropsWithChildren<ModalProps>) {
  const modal = useRef<HTMLDivElement>(null);

  const handleClickOutside = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (!modal.current) return;
    if (modal.current === event.target && onClick) onClick();
  };
  return show ? (
    <>
      <NoOverflow />
      <DarkenDiv $zIndex={zIndexBase} />
      <ModalDiv ref={modal} $zIndex={zIndexBase} onClick={handleClickOutside}>
        <ModalContentWrapper $xsCentered={xsCentered}>
          {children}
        </ModalContentWrapper>
      </ModalDiv>
    </>
  ) : (
    <div />
  );
}

import { css, styled } from "styled-components";
import { CssMixin } from "theme";

type PaddingSize = "none" | "md" | "lg" | "xl";

export const paddingSize: Record<PaddingSize, number> = {
  none: 0,
  md: 20,
  lg: 32,
  xl: 40,
};

type BgTheme = "box" | "modal" | "transparent";

const BoxTheme = css`
  background-color: ${({ theme }) => theme.colors.boxBg};
  border: 1px solid ${({ theme }) => theme.colors.gray10};
`;

const TransparentTheme = css`
  background-color: ${({ theme }) => theme.colors.transparent};
  border: ${({ theme }) => theme.layoutBorder};
`;

const ModalTheme = css`
  background-color: ${({ theme }) => theme.colors.modalBg};
  border: 1px solid rgba(255 255 255 / 5%);
  box-shadow: 0 4px 28px 4px rgba(0 0 0 / 15%);
`;

const BG_THEME: Record<BgTheme, CssMixin> = {
  box: BoxTheme,
  modal: ModalTheme,
  transparent: TransparentTheme,
};

export interface DialogContainerProps {
  $width?: string;
  $minWidth?: string;
  $maxWidth?: string;

  $minHeight?: string;
  $maxHeight?: string;
  $padding?: PaddingSize;
  $colorTheme?: BgTheme;
}

export const DialogContainer = styled.div<DialogContainerProps>`
  padding: ${({ $padding = "md", theme: { rem } }) =>
    rem(paddingSize[$padding])};

  width: ${({ $width = "100%" }) => $width};
  min-width: ${({ $minWidth }) => $minWidth};
  max-width: ${({ $maxWidth }) => $maxWidth};

  min-height: ${({ $minHeight = "550px" }) => $minHeight};
  max-height: ${({ $maxHeight }) => $maxHeight};

  ${({ theme }) => theme.flexRow.columnNoWrap}

  justify-content: flex-start;
  align-items: center;
  ${({ $colorTheme = "box" }) => BG_THEME[$colorTheme]};
  border-radius: ${({ theme: { rem, borderRadius } }) => rem(borderRadius.md)};

  ${({ $padding = "md", theme }) => theme.mediaWidth.medium`
    padding: ${
      $padding === "lg" ? theme.rem(24) : theme.rem(paddingSize[$padding])
    };
    border-radius: ${theme.rem(10)};
  `};
`;

export const DialogTitle = styled.h2`
  margin-left: 20px;
`;

import { Col, Title, Typography } from "components/elements";
import { useAccount } from "wagmi";

export function Account() {
  const { address, chain } = useAccount();

  return (
    <Col $gap={4}>
      <Title>Welcome to Gearbox Mgmt!</Title>

      <Col $gap={2}>
        <Typography>Successfully connected to Safe with:</Typography>
        <Col $noGutters>
          <Typography>
            <b>Chain:</b> {chain?.name}
          </Typography>
          <Typography>
            <b>Address:</b> {address}
          </Typography>
        </Col>
      </Col>
    </Col>
  );
}

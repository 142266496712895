import { styled } from "styled-components";

import { DialogContainer } from "../DialogWindow";

export const StyledDialogModalContainer = styled(DialogContainer)`
  ${({ theme }) => theme.mediaWidth.small`
    width: 440px;
  `};

  ${({ theme }) => theme.mediaWidth.extraSmall`
    width: 100%;
    min-height: 100vh;
  `};
`;

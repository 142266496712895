import { Col } from "components/elements";
import { Batches } from "components/modules";
import { getBatchList, selectBatchesInfo } from "features/batches/batchesSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect } from "react";
import { useAccount } from "wagmi";

export function Upload() {
  const { chainId, address } = useAccount();

  const dispatch = useAppDispatch();
  const { status } = useAppSelector(selectBatchesInfo(chainId, address));

  useEffect(() => {
    if (status === "idle") {
      dispatch(getBatchList({ safeAddress: address, chainId })).catch(e => {
        console.log("Failed to load Gearbox State: ", e);
      });
    }
  }, [dispatch]);

  return (
    <Col>
      <Batches />
    </Col>
  );
}

import { Col, StyledDropdown, Subtitle, Typography } from "components/elements";
import dayjs from "dayjs";
import { NewTransaction } from "features/createTransactions/createTransactions.types";
import { addTransaction } from "features/createTransactions/createTransactionsSlice";
import { useAppDispatch } from "hooks";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Cmd } from "types/cmd";
import { NewTxFormFormValues } from "types/transactions";
import { TRANSACTION_CMD_LIST } from "utils/constants";

import { AddCollateralTokenModule } from "./NewTxFormModules/AddCollateralTokenModule";
import { ForbidBorrowingModule } from "./NewTxFormModules/ForbidBorrowingModule";
import { RampLiquidationThresholdModule } from "./NewTxFormModules/RampLiquidationThresholdModule";
import { SetDebtLimitModule } from "./NewTxFormModules/SetDebtLimitModule";
import { SetFeesModule } from "./NewTxFormModules/SetFeesModule";
import { SetTokenLimitModule } from "./NewTxFormModules/SetTokenLimitModule";

const defaultValues: NewTxFormFormValues = {
  cmd: undefined,
  pool: undefined,
  token: undefined,

  limit: undefined,
  threshold: undefined,

  rampDuration: 14,
  rampStart: dayjs().add(7, "day").unix(),

  feeInterest: undefined,
  feeLiquidation: undefined,
  liquidationPremium: undefined,
  feeLiquidationExpired: undefined,
  liquidationPremiumExpired: undefined,
};

export function NewTxForm() {
  const rhfMethods = useForm<NewTxFormFormValues>({
    defaultValues,
    mode: "onChange",
  });

  const { setValue, watch, handleSubmit, reset } = rhfMethods;

  const watchedValue = watch();
  const cmd = useMemo(() => watchedValue.cmd, [watchedValue.cmd]);

  const dispatch = useAppDispatch();

  const submitNewTx = (formData: NewTxFormFormValues) => {
    if (formData.cmd !== undefined) {
      dispatch(addTransaction(formData as NewTransaction));
      reset();
    } else {
      console.log("Error: Invalid form values");
    }
  };

  return (
    <FormProvider {...rhfMethods}>
      <form id={"add-tx-form"} onSubmit={handleSubmit(submitNewTx)}>
        <Col $noGutters $gap={4}>
          <Subtitle>Add Transaction</Subtitle>

          <Col $noGutters $gap={2}>
            <Typography>Action</Typography>
            <StyledDropdown
              items={Object.values(Cmd).map(cmd => TRANSACTION_CMD_LIST[cmd])}
              label={cmd?.label ?? "Select action"}
              onSelect={i => {
                reset();
                setValue("cmd", i);
              }}
            />
          </Col>

          {cmd?.id === Cmd.setTokenLimit && <SetTokenLimitModule />}
          {cmd?.id === Cmd.addCollateralToken && <AddCollateralTokenModule />}
          {cmd?.id === Cmd.rampLiquidationThreshold && (
            <RampLiquidationThresholdModule />
          )}
          {cmd?.id === Cmd.setMinDebtLimit && (
            <SetDebtLimitModule limitType="min" />
          )}
          {cmd?.id === Cmd.setMaxDebtLimit && (
            <SetDebtLimitModule limitType="max" />
          )}
          {cmd?.id === Cmd.setFees && <SetFeesModule />}
          {cmd?.id === Cmd.forbidBorrowing && <ForbidBorrowingModule />}
        </Col>
      </form>
    </FormProvider>
  );
}

import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "store";
import { BatchBundle } from "types/batches";
import { AsyncBaseState } from "types/store";
import { prepareBatches, prepareBatchRequest } from "utils/batches";
import { json_parse, json_stringify } from "utils/bigint-serializer";
import { getSafeTransactionList } from "utils/transactions";

import {
  BatchInfo,
  GenerateBatchesProps,
  GetBatchesProps,
  ParseBatchesResponse,
} from "./batches.types";

const batchesAdapter = createEntityAdapter<BatchInfo>();

const initialState = batchesAdapter.getInitialState<AsyncBaseState>({
  status: "idle",
  error: undefined,
});

export const getBatchList = createAsyncThunk<BatchInfo[], GetBatchesProps>(
  "batches/getBatchList",
  async ({ safeAddress, chainId }, { rejectWithValue }) => {
    if (!safeAddress || !chainId) {
      return rejectWithValue("Invalid Chain ID or Safe address");
    }

    try {
      const [queuedTransactions, batchResponse] = await Promise.all([
        getSafeTransactionList(chainId, safeAddress),
        axios.get<Array<BatchBundle>>("http://localhost:8000"),
      ]);

      return prepareBatches(
        json_parse(json_stringify(batchResponse.data)),
        queuedTransactions,
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data.error ?? error.message);
      }
      return rejectWithValue(error);
    }
  },
);

export const refetchBatchList = createAsyncThunk<BatchInfo[], GetBatchesProps>(
  "batches/refetchBatchList",
  async ({ safeAddress, chainId }, { rejectWithValue, getState }) => {
    if (!safeAddress || !chainId) {
      return rejectWithValue("Invalid Chain ID or Safe address");
    }

    try {
      const queuedTransactions = await getSafeTransactionList(
        chainId,
        safeAddress,
      );

      const state = getState() as RootState;
      const batchesState = Object.values(state.batches.entities);

      return prepareBatches(batchesState, queuedTransactions);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data.error ?? error.message);
      }
      return rejectWithValue(error);
    }
  },
);

export const generateBatchList = createAsyncThunk<
  BatchInfo[],
  GenerateBatchesProps
>(
  "batches/generateBatchList",
  async (
    { safeAddress, chainId, getStateDiff = false, fname },
    { rejectWithValue, getState },
  ) => {
    if (!safeAddress || !chainId || !fname) {
      console.log("Params error");

      return rejectWithValue("Invalid Chain ID or Safe address");
    }

    try {
      const state = getState() as RootState;
      const batchesState = Object.values(state.createTransactions.transactions);

      const [queuedTransactions, batchResponse] = await Promise.all([
        getSafeTransactionList(chainId, safeAddress),
        axios.post<ParseBatchesResponse>("http://localhost:8000/parse", {
          fname,
          returnStateDiff: getStateDiff,
          batch: prepareBatchRequest(batchesState),
        }),
      ]);

      if (getStateDiff && batchResponse.data.state) {
        console.log(
          "SateDiff.json:\n",
          json_parse(json_stringify(batchResponse.data.state)),
        );
      }

      return prepareBatches(
        json_parse(json_stringify(batchResponse.data.batches)),
        queuedTransactions,
      );
    } catch (error) {
      console.log(error);

      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data.error ?? error.message);
      }
      return rejectWithValue(error);
    }
  },
);

const batchesSlice = createSlice({
  name: "batches",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getBatchList.pending, state => {
        state.status = "loading";
      })
      .addCase(getBatchList.fulfilled, (state, action) => {
        batchesAdapter.setAll(state, action.payload);
        state.status = "succeeded";
      })
      .addCase(getBatchList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(refetchBatchList.pending, state => {
        state.status = "loading";
      })
      .addCase(refetchBatchList.fulfilled, (state, action) => {
        batchesAdapter.setAll(state, action.payload);
        state.status = "succeeded";
      })
      .addCase(refetchBatchList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(generateBatchList.pending, state => {
        state.status = "loading";
      })
      .addCase(generateBatchList.fulfilled, (state, action) => {
        batchesAdapter.setAll(state, action.payload);
        state.status = "succeeded";
      })
      .addCase(generateBatchList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default batchesSlice.reducer;

const { selectById, selectAll } = batchesAdapter.getSelectors<RootState>(
  state => state.batches,
);

const selectBatchesStatus = (state: RootState) => state.batches.status;
const selectBatchesError = (state: RootState) => state.batches.error;

export const selectFullBatchesInfo = createSelector(
  [selectBatchesStatus, selectBatchesError, selectAll],
  (status, error, batchesInfo) => ({
    status,
    error,
    batchesInfo,
  }),
);

export const selectBatchesLength = createSelector(
  [selectBatchesStatus, selectBatchesError, selectAll],
  (status, error, batches) => ({
    status,
    error,
    length: batches.length,
  }),
);

export const selectBatchesInfo = (
  chainId?: number | string,
  safeAddress?: `0x${string}`,
) =>
  createSelector(
    [selectBatchesStatus, selectBatchesError, selectAll],
    (status, error, batches) => ({
      status,
      error,
      batchesInfo: batches.filter(
        batch =>
          batch.chainId === chainId?.toString() &&
          batch.safeAddress.toLowerCase() === safeAddress?.toLowerCase(),
      ),
    }),
  );

export const selectBatchInfo = (id?: number) =>
  createSelector(
    [
      selectBatchesStatus,
      selectBatchesError,
      (state: RootState) =>
        id !== undefined ? selectById(state, id) : undefined,
    ],
    (status, error, batchInfo) => ({
      status,
      error,
      batchInfo,
    }),
  );

import { Property } from "csstype";
import { css, styled } from "styled-components";

export const Table = styled.table`
  width: 100%;
  border-bottom: none;
`;

interface CommonMixinProps {
  $vAlign?: Property.VerticalAlign;
}

const CommonMixin = css<CommonMixinProps>`
  vertical-align: ${({ $vAlign = "middle" }) => $vAlign};
`;

export interface TrProps extends CommonMixinProps {
  $last?: boolean;
  $height?: Property.Height;
}

export const Tr = styled.tr<TrProps>`
  height: ${({ $height = "40px" }) => $height};
  border-bottom: ${({ $last, theme }) =>
    $last ? "none" : `1px solid ${theme.colors.borderSecondary}`};
  ${CommonMixin}
`;

export const ActiveTr = styled(Tr)`
  cursor: pointer;

  &:hover {
    td {
      background-color: ${({ theme }) => theme.colors.primaryAltHover};
    }
  }

  &[data-disabled="true"] {
    cursor: default;

    &:hover {
      td {
        background-color: transparent;
      }
    }
  }
`;

const theadCell = css`
  font-size: ${({ theme }) => theme.rem(14)};
  line-height: ${({ theme }) => theme.rem(17)};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.textSecondary2};
`;

export interface ThProps extends CommonMixinProps {
  $align?: Property.TextAlign;
}

export const Th = styled.th<ThProps>`
  padding-right: ${({ theme, $align }) =>
    $align === "right" ? theme.rem(8) : "unset"};
  padding-left: ${({ theme, $align }) =>
    $align === "left" ? theme.rem(8) : "unset"};
  text-align: ${({ $align = "left" }) => $align};
  ${theadCell}
  ${CommonMixin}
`;

export interface TdProps extends CommonMixinProps {
  $align?: Property.TextAlign;
}

export const Td = styled.td<TdProps>`
  padding-right: ${({ theme, $align }) =>
    $align === "right" ? theme.rem(8) : "unset"};
  padding-left: ${({ theme, $align }) =>
    $align === "left" ? theme.rem(8) : "unset"};
  text-align: ${({ $align = "left" }) => $align};
  ${CommonMixin}
`;

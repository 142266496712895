import { styled } from "styled-components";

interface ColProps {
  $pl?: number;
  $pr?: number;
  $pt?: number;
  $pb?: number;
}

export const Card = styled.div<ColProps>`
  border: ${({ theme }) => theme.layoutBorder};
  border-radius: 12px;
  padding: ${({ theme }) => theme.rem(20)};
`;

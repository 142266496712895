import { styled } from "styled-components";

interface BundleBoxProps {
  $border: boolean;
}

export const BundleBox = styled.div<BundleBoxProps>`
  border-bottom: ${({ $border, theme }) =>
    $border ? theme.layoutBorder : "none"};
`;

import {
  Col,
  Drawer,
  Input,
  Loader,
  Row,
  StyledButton,
  Title,
  Toggle,
  Typography,
} from "components/elements";
import {
  generateBatchList,
  selectFullBatchesInfo,
} from "features/batches/batchesSlice";
import { selectCreateTransactions } from "features/createTransactions/createTransactionsSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import { Fragment, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useAccount } from "wagmi";

import { CreateTransactionCard } from "./CreateTransactionCard";
import { NewTxForm } from "./NewTxForm";

interface CreateFormValues {
  stateDiff: boolean;
  name: string;
}

const defaultValues: CreateFormValues = {
  stateDiff: true,
  name: "",
};

export function Constructor() {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const rhfMethods = useForm<CreateFormValues>({
    defaultValues,
    mode: "onChange",
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = rhfMethods;

  const { chainId, address } = useAccount();

  const dispatch = useAppDispatch();
  const { status } = useAppSelector(selectFullBatchesInfo);
  const { transactions } = useAppSelector(selectCreateTransactions);

  const submitTransactions = async (formData: CreateFormValues) => {
    await dispatch(
      generateBatchList({
        safeAddress: address,
        chainId,
        fname: formData.name,
        getStateDiff: formData.stateDiff,
      }),
    );
  };

  return (
    <>
      <FormProvider {...rhfMethods}>
        <form id={"upload-tx"} onSubmit={handleSubmit(submitTransactions)}>
          <Col $gap={4}>
            <Title>Constructor</Title>

            <Col $noGutters $gap={2} style={{}}>
              <Typography>GIP name</Typography>
              <Controller
                name={"name"}
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    required
                    placeholder={"gip-137-batches"}
                    error={errors["name"]?.message}
                    disabled={status === "loading"}
                  />
                )}
              />
            </Col>
            {transactions.length === 0 && (
              <Typography>You did not add any transactions</Typography>
            )}
            {transactions.map((_, index) => (
              <Fragment key={index}>
                <CreateTransactionCard index={index} />
              </Fragment>
            ))}

            <Row $w={"100%"} $noGutters $justify="flex-end">
              <Row $noGutters $gap={2}>
                <Typography $size="textMd">
                  Print stateDiff.json to console
                </Typography>
                <Controller
                  name={"stateDiff"}
                  control={control}
                  render={({ field }) => (
                    <Toggle
                      checked={field.value}
                      onChange={field.onChange}
                      disabled={status === "loading"}
                    />
                  )}
                />
              </Row>
            </Row>

            <Row $w={"100%"} $noGutters $justify="flex-end">
              <Row $noGutters $gap={2}>
                <StyledButton
                  onClick={() => setIsOpen(true)}
                  $width="compact"
                  $color="outlined"
                  disabled={status === "loading"}
                  type="button"
                >
                  Add Tx
                </StyledButton>

                <StyledButton
                  disabled={status === "loading" || transactions.length === 0}
                  type="submit"
                >
                  {status === "loading" ? <Loader /> : "Generate Batches"}
                </StyledButton>
              </Row>
            </Row>
          </Col>
        </form>
      </FormProvider>

      <Drawer opened={isOpen} onClose={() => setIsOpen(false)}>
        <NewTxForm />
      </Drawer>
    </>
  );
}

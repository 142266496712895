import { css, styled } from "styled-components";
import { Colors, CssMixin } from "theme";

export const LinkText = styled.a`
  ${({ theme }) => `color: ${theme.colors.textPrimary};`}
  text-decoration: none;
  width: fit-content;

  &:hover {
    ${({ theme }) => `color: ${theme.colors.mainAccent};`}
  }
`;

export const Title = styled.h1`
  font-size: ${({ theme }) => theme.rem(32)};
  line-height: ${({ theme }) => theme.rem(40)};
`;

export const Subtitle = styled.h2`
  font-size: ${({ theme }) => theme.rem(20)};
  line-height: ${({ theme }) => theme.rem(24)};
  font-weight: bold;
`;

interface DescriptionProps {
  $textAlign?: "left" | "right" | "center";
}

export const Description = styled.div<DescriptionProps>`
  font-size: ${({ theme }) => theme.rem(12)};
  line-height: ${({ theme }) => theme.rem(14)};
  text-align: ${({ $textAlign }) => $textAlign};
  color: ${({ theme }) => theme.colors.textSecondary};
  border-bottom: none;
`;

interface ColoredTextProps {
  $alert?: boolean;
  $warning?: boolean;
  $success?: boolean;
}

export const ColoredText = styled.span<ColoredTextProps>`
  color: ${({ $warning = false, $alert = false, $success = false, theme }) => {
    if ($alert) return theme.colors.additionalAlert;
    if ($warning) return theme.colors.additionalWarning;
    if ($success) return theme.colors.additionalSuccess;
    return "inherit";
  }};
`;

type TypographySize = "unset" | "textMain" | "textMd" | "textSm";

const TYPOGRAPHY_UNSET = css`
  font-size: unset;
  line-height: unset;
`;

const TYPOGRAPHY_MAIN = css`
  font-size: ${({ theme }) => theme.rem(16)};
  line-height: ${({ theme }) => theme.rem(19)};
`;

const TYPOGRAPHY_MD = css`
  font-size: ${({ theme }) => theme.rem(14)};
  line-height: 140%;
`;

const TYPOGRAPHY_SM = css`
  font-size: ${({ theme }) => theme.rem(12)};
  line-height: ${({ theme }) => theme.rem(14)};
`;

const TYPOGRAPHY_SIZE: Record<TypographySize, CssMixin> = {
  unset: TYPOGRAPHY_UNSET,

  textMain: TYPOGRAPHY_MAIN,
  textMd: TYPOGRAPHY_MD,
  textSm: TYPOGRAPHY_SM,
};

type TypographyWeight = "unset" | "bold";

const TYPOGRAPHY_WIGHT_UNSET = css`
  font-weight: unset;
`;

const TYPOGRAPHY_WEIGHT_BOLD = css`
  font-weight: 700;
`;

const TYPOGRAPHY_WEIGHT: Record<TypographyWeight, CssMixin> = {
  unset: TYPOGRAPHY_WIGHT_UNSET,
  bold: TYPOGRAPHY_WEIGHT_BOLD,
};

type TypographyColor = keyof Colors;

interface TypographyProps {
  $size?: TypographySize;
  $weight?: TypographyWeight;
  $color?: TypographyColor;
}

export const Typography = styled.span<TypographyProps>`
  ${({ $size = "unset" }) => TYPOGRAPHY_SIZE[$size]}
  ${({ $weight = "unset" }) => TYPOGRAPHY_WEIGHT[$weight]}
  ${({ theme, $color }) => ($color ? `color: ${theme.colors[$color]};` : "")}
`;

export const ErrorText = styled.div`
  margin-top: 8px;

  font-size: ${({ theme }) => theme.rem(12)};
  line-height: ${({ theme }) => theme.rem(14)};
  color: ${({ theme }) => theme.colors.additionalAlert};
`;
export const WarningText = styled.div`
  margin-top: 8px;

  font-size: ${({ theme }) => theme.rem(12)};
  line-height: ${({ theme }) => theme.rem(14)};
  color: ${({ theme }) => theme.colors.additionalWarning};
`;

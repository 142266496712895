import { HTMLInputProps } from "types/ui";

import { ErrorText } from "../Text";
import {
  IconWrap,
  InputBg,
  InputCard,
  InputColorTheme,
  InputSize,
  Root,
  StyledInput,
} from "./styles";

type IconOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;

export interface InputProps extends HTMLInputProps {
  startingIcon?: React.ReactNode;
  onStartingIconClick?: IconOnClick;

  endingIcon?: React.ReactNode;
  onEndingIconClick?: IconOnClick;

  inputSize?: InputSize;
  colorTheme?: InputColorTheme;

  error?: React.ReactNode;
}

export function Input({
  startingIcon,
  onStartingIconClick,

  endingIcon,
  onEndingIconClick,

  colorTheme = "filledDark",
  inputSize = "lg",
  error,
  ...restProps
}: InputProps) {
  return (
    <Root>
      <InputCard $size={inputSize} $colorTheme={colorTheme}>
        {startingIcon && (
          <IconWrap
            $size={inputSize}
            $colorTheme={colorTheme}
            $position="left"
            onClick={onStartingIconClick}
            tabIndex={onStartingIconClick ? -1 : undefined}
            $hasPointer={!!onStartingIconClick}
          >
            {startingIcon}
          </IconWrap>
        )}
        <StyledInput
          $size={inputSize}
          $colorTheme={colorTheme}
          data-error={!!error}
          {...restProps}
        />
        {endingIcon && (
          <IconWrap
            $size={inputSize}
            $colorTheme={colorTheme}
            $position="right"
            onClick={onEndingIconClick}
            tabIndex={onEndingIconClick ? -1 : undefined}
            $hasPointer={!!onEndingIconClick}
          >
            {endingIcon}
          </IconWrap>
        )}
        <InputBg $size={inputSize} $colorTheme={colorTheme} />
      </InputCard>
      {error && <ErrorText>{error}</ErrorText>}
    </Root>
  );
}

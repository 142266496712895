import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Card, Col, IconButton, Row, Typography } from "components/elements";
import {
  removeTransaction,
  selectCreateTransaction,
} from "features/createTransactions/createTransactionsSlice";
import { useAppDispatch, useAppSelector } from "hooks";

import { CreateTransactionInfo } from "./CreateTransactionInfo";

export interface CreateTransactionCardProps {
  index: number;
}

export function CreateTransactionCard({ index }: CreateTransactionCardProps) {
  const dispatch = useAppDispatch();

  const { transaction } = useAppSelector(state =>
    selectCreateTransaction(state, index),
  );

  if (!transaction) {
    return <></>;
  }

  return (
    <Card>
      <Col $noGutters $gap={4}>
        <Row $justify="space-between" $align="center" $noGutters>
          <Typography $weight="bold">{transaction.cmd.label}</Typography>
          <IconButton
            icon={faTrash}
            $size="sm"
            onClick={() => dispatch(removeTransaction(index))}
          />
        </Row>

        <CreateTransactionInfo {...transaction} />
      </Col>
    </Card>
  );
}

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import React, { useMemo, useState } from "react";
import { Colors } from "theme";
import { isString } from "utils/typeGuards";

import { Dropdown, DropdownProps } from "../Dropdown";
import { FaIconStyled } from "../FaIcon";
import { Input } from "../Input";
import { InputColorTheme } from "../Input/styles";
import {
  DropdownBox,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
  DropdownTheme,
  iconSizes,
  IconWrap,
  Size,
} from "./styles";

interface BaseItem {
  label: React.ReactNode;
}

type DropdownItem = BaseItem | string;

export interface StyledDropdownProps<T extends DropdownItem, B extends BaseItem>
  extends Omit<DefaultDropdownLabel, "onClick" | "opened"> {
  items: Array<T> | B;
  opens?: DropdownProps["opens"];
  onSelect: (item: T) => void;
  width?: string;
  contentWidth?: string;
  menuMinWidth?: string;
}

export function StyledDropdown<T extends DropdownItem, B extends BaseItem>({
  items,

  opens = "left",
  width = "100%",
  contentWidth,
  menuMinWidth = "10rem",
  onSelect,
  ...labelProps
}: StyledDropdownProps<T, B>) {
  const [opened, setOpened] = useState(false);

  const handleOpenedClick = () => setOpened(!opened);
  const handleClosedClick = () => setOpened(false);

  return (
    <Dropdown
      opened={opened}
      opens={opens}
      onClose={handleClosedClick}
      width={width}
      contentWidth={contentWidth}
      title={
        <DefaultDropdownLabel
          opened={opened}
          onClick={handleOpenedClick}
          {...labelProps}
        />
      }
    >
      {!Array.isArray(items)
        ? items.label
        : items.length > 0 && (
            <DropdownBox>
              <DropdownMenu $minWidth={menuMinWidth}>
                {items.map((item, index) => (
                  <DropdownItem onClick={() => onSelect(item)} key={index}>
                    {typeof item === "string" ? item : item.label}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </DropdownBox>
          )}
    </Dropdown>
  );
}

export interface DefaultDropdownLabel {
  opened: boolean;

  label: React.ReactNode;
  onClick: () => void;

  theme?: DropdownTheme;
  size?: Size;
  disabled?: boolean;
  hideIcon?: boolean;
  icon?: IconProp;

  as?: string;

  iconColor?: keyof Colors;
  iconHoverColor?: keyof Colors;
}

export function DefaultDropdownLabel({
  opened,

  label,
  onClick,

  theme = "filled",
  size = "md",
  disabled = false,
  icon = faCaretDown,
  hideIcon = false,

  as,
  iconColor,
  iconHoverColor,
}: DefaultDropdownLabel) {
  return (
    <DropdownButton
      disabled={disabled}
      $theme={theme}
      onClick={onClick}
      $size={size}
      as={as}
      type="button"
    >
      {label}
      {!hideIcon && (
        <IconWrap $opened={opened}>
          <FaIconStyled
            $size={iconSizes[size].arrow}
            icon={icon}
            $disabledColor="textSecondary"
            data-disabled={disabled}
            $color={iconColor}
            $hoverColor={iconHoverColor}
          />
        </IconWrap>
      )}
    </DropdownButton>
  );
}

import { Col, Contract, Row, Typography } from "components/elements";
import { unix } from "dayjs";
import { NewTransaction } from "features/createTransactions/createTransactions.types";
import { Cmd } from "types/cmd";

export function CreateTransactionInfo({
  cmd,
  pool,
  token,
  limit,
  cm,
  threshold,
  rampStart,
  rampDuration,
  feeInterest,
  feeLiquidation,
  liquidationPremium,
  feeLiquidationExpired,
  liquidationPremiumExpired,
}: NewTransaction) {
  return (
    <Col $noGutters>
      {[Cmd.setTokenLimit].includes(cmd.id) && pool && (
        <Row $noGutters $gap={16} $align="center" $justify="space-between">
          <Typography $size="textMd" $weight="bold">
            Pool:
          </Typography>
          <Row $noGutters $gap={2} $align="center">
            <Typography $size="textMd">{pool.label}</Typography>
            <Contract isDescription address={pool.address} />
          </Row>
        </Row>
      )}

      {[
        Cmd.addCollateralToken,
        Cmd.rampLiquidationThreshold,
        Cmd.setMinDebtLimit,
        Cmd.setMaxDebtLimit,
        Cmd.setFees,
        Cmd.forbidBorrowing,
      ].includes(cmd.id) &&
        cm && (
          <Row $noGutters $gap={16} $align="center" $justify="space-between">
            <Typography $size="textMd" $weight="bold">
              Credit manager:
            </Typography>
            <Row $noGutters $gap={2} $align="center">
              <Typography $size="textMd">{cm.label}</Typography>
              <Contract isDescription address={cm.address} />
            </Row>
          </Row>
        )}

      {[
        Cmd.setTokenLimit,
        Cmd.addCollateralToken,
        Cmd.rampLiquidationThreshold,
      ].includes(cmd.id) &&
        token && (
          <Row $noGutters $gap={16} $align="center" $justify="space-between">
            <Typography $size="textMd" $weight="bold">
              Token:
            </Typography>
            <Row $noGutters $gap={2} $align="center">
              <Typography $size="textMd">{token.label}</Typography>
              <Contract isDescription address={token.address} />
            </Row>
          </Row>
        )}

      {[Cmd.setTokenLimit, Cmd.setMinDebtLimit, Cmd.setMaxDebtLimit].includes(
        cmd.id,
      ) && (
        <Row $noGutters $gap={16} $align="center" $justify="space-between">
          <Typography $size="textMd" $weight="bold">
            {cmd.id === Cmd.setTokenLimit && "New limit:"}
            {cmd.id === Cmd.setMinDebtLimit && "Min debt:"}
            {cmd.id === Cmd.setMaxDebtLimit && "Max debt:"}
          </Typography>
          <Typography $size="textMd">{Number(limit)}</Typography>
        </Row>
      )}

      {[Cmd.addCollateralToken, Cmd.rampLiquidationThreshold].includes(
        cmd.id,
      ) && (
        <Row $noGutters $gap={16} $align="center" $justify="space-between">
          <Typography $size="textMd" $weight="bold">
            {cmd.id === Cmd.addCollateralToken && "Liquidation threshold:"}
            {cmd.id === Cmd.rampLiquidationThreshold &&
              "New liquidation threshold:"}
          </Typography>
          <Typography $size="textMd">{Number(threshold)}</Typography>
        </Row>
      )}

      {[Cmd.rampLiquidationThreshold].includes(cmd.id) && rampStart && (
        <Row $noGutters $gap={16} $align="center" $justify="space-between">
          <Typography $size="textMd" $weight="bold">
            Ramp start:
          </Typography>
          <Typography $size="textMd">
            {unix(rampStart).format("DD.MM.YYYY HH:mm")}
          </Typography>
        </Row>
      )}

      {[Cmd.rampLiquidationThreshold].includes(cmd.id) && rampDuration && (
        <Row $noGutters $gap={16} $align="center" $justify="space-between">
          <Typography $size="textMd" $weight="bold">
            Ramp duration:
          </Typography>
          <Typography $size="textMd">{`${rampDuration} (days)`}</Typography>
        </Row>
      )}

      {cmd.id === Cmd.setFees && rampDuration && (
        <>
          <Row $noGutters $gap={16} $align="center" $justify="space-between">
            <Typography $size="textMd" $weight="bold">
              Fee Interest:
            </Typography>
            <Typography $size="textMd">{Number(feeInterest)}%</Typography>
          </Row>
          <Row $noGutters $gap={16} $align="center" $justify="space-between">
            <Typography $size="textMd" $weight="bold">
              Fee Liquidation:
            </Typography>
            <Typography $size="textMd">{Number(feeLiquidation)}%</Typography>
          </Row>
          <Row $noGutters $gap={16} $align="center" $justify="space-between">
            <Typography $size="textMd" $weight="bold">
              Fee Liquidation Premium:
            </Typography>
            <Typography $size="textMd">
              {Number(liquidationPremium)}%
            </Typography>
          </Row>
          <Row $noGutters $gap={16} $align="center" $justify="space-between">
            <Typography $size="textMd" $weight="bold">
              Fee Liquidation Expired:
            </Typography>
            <Typography $size="textMd">
              {Number(feeLiquidationExpired)}%
            </Typography>
          </Row>
          <Row $noGutters $gap={16} $align="center" $justify="space-between">
            <Typography $size="textMd" $weight="bold">
              Liquidation Premium Expired:
            </Typography>
            <Typography $size="textMd">
              {Number(liquidationPremiumExpired)}%
            </Typography>
          </Row>
        </>
      )}
    </Col>
  );
}

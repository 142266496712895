import { GearboxState, getDecimals } from "@gearbox-protocol/sdk-gov";
import { CmInfo, PoolInfo, SupportedTokenInfo } from "types/transactions";
import { formatUnits } from "viem";

export const getPoolTokenLimit = ({
  state,
  pool,
  token,
}: {
  state?: GearboxState;
  pool?: PoolInfo;
  token?: SupportedTokenInfo;
}) => {
  if (!state || !pool || !token) {
    return undefined;
  }

  const quota = state.poolState.find(
    poolState =>
      poolState.pool.address.toLowerCase() === pool.address.toLowerCase(),
  )?.poolQuotaKeeper.quotas[token.address];

  return quota ? Number(formatUnits(quota.limit, pool.decimals)) : 0;
};

export const getCmTokenLt = ({
  state,
  cm,
  token,
}: {
  state?: GearboxState;
  cm?: CmInfo;
  token?: SupportedTokenInfo;
}) => {
  if (!state || !cm || !token) {
    return undefined;
  }

  const lt = state.creditState.find(
    creditState =>
      creditState.creditManager.address.toLowerCase() ===
      cm.address.toLowerCase(),
  )?.creditManager.collateralTokens[token.address];

  return (lt ?? 0) / 100;
};

export const getCmDebtLimit = ({
  state,
  cm,
  limitType,
}: {
  state?: GearboxState;
  cm?: CmInfo;
  limitType: "min" | "max";
}) => {
  if (!state || !cm) {
    return undefined;
  }

  const creditState = state.creditState.find(
    creditState =>
      creditState.creditManager.address.toLowerCase() ===
      cm.address.toLowerCase(),
  );

  const limit =
    limitType === "min"
      ? creditState?.creditFacade.minDebt
      : creditState?.creditFacade.maxDebt;
  const underlying = creditState?.creditManager.underlying;

  if (!limit || !underlying) {
    return undefined;
  }
  const decimals = getDecimals(underlying);

  return Number(formatUnits(BigInt(limit), decimals));
};

export const getCmFeeInfo = ({
  state,
  cm,
}: {
  state?: GearboxState;
  cm?: CmInfo;
}) => {
  if (!state || !cm) {
    return undefined;
  }

  const creditManager = state.creditState.find(
    creditState =>
      creditState.creditManager.address.toLowerCase() ===
      cm.address.toLowerCase(),
  )?.creditManager;

  if (!creditManager) {
    return undefined;
  }

  return {
    feeInterest: Number((creditManager.feeInterest / 100).toFixed(2)),
    feeLiquidation: Number((creditManager.feeLiquidation / 100).toFixed(2)),
    liquidationPremium: Number(
      (100 - creditManager.liquidationDiscount / 100).toFixed(2),
    ),
    feeLiquidationExpired: Number(
      (creditManager.feeLiquidationExpired / 100).toFixed(2),
    ),
    liquidationPremiumExpired: Number(
      (100 - creditManager.liquidationDiscountExpired / 100).toFixed(2),
    ),
  };
};

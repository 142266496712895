import { PropsWithChildren, useMemo } from "react";
import {
  createGlobalStyle,
  ThemeProvider as StyledComponentsThemeProvider,
} from "styled-components";

import { FONT_FAMILY } from "./constants";
import { theme as createTheme } from "./functions";
import { BgMode, Colors } from "./types";

interface ThemeProviderProps {
  darkMode?: boolean;
  bgMode?: BgMode;
}

export function ThemeProvider({
  children,
  darkMode = false,
  bgMode = "solidLight",
}: PropsWithChildren<ThemeProviderProps>) {
  const themeObject = useMemo(
    () => createTheme({ darkMode, bgMode }),
    [darkMode, bgMode],
  );

  return (
    <StyledComponentsThemeProvider theme={themeObject}>
      <>{children}</>
    </StyledComponentsThemeProvider>
  );
}

const BG_COLORS: Record<BgMode, keyof Colors> = {
  solid: "boxBg",
  solidLight: "pageBg",
};

export const DefaultGlobalStyle = createGlobalStyle`
  html,
  body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
 
    font-family: ${FONT_FAMILY};
    font-size: ${({ theme }) => theme.rem(16)};
    line-height: ${({ theme }) => theme.rem(19)};
    font-variant: none;
    color: ${({ theme }) => theme.colors.textPrimary};

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
    font-feature-settings: 'ss01' on, 'ss02' on, 'cv01' on, 'cv03' on;
    

    background: ${({ theme }) => theme.colors[BG_COLORS[theme.bgMode]]};    
    background-attachment: fixed;

    ${({ theme }) => theme.mediaWidth.small`
      font-size: ${({ theme }) => theme.rem(14)};
    `};
  }

  * {
    box-sizing: border-box;
  }

  table {
    border-collapse: collapse;
  }

  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0.5rem;
    line-height: 1.2;
    font-weight: 500;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  button {  
    font-size: inherit;
    line-height: inherit;

    cursor: pointer;
    user-select: none;
  
    appearance: button;
  }

  img, svg {
    vertical-align: middle;
  }

  a {
    color: ${({ theme }) => theme.colors.textLink};
  }

  input, textarea, button {
    font-family: ${({ theme }) => theme.fontFamily.main};
    font-display: fallback;
  }



  article,
  aside,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  nav,
  section {
    display: block;
  }

  h2 {
    font-size: ${({ theme }) => theme.rem(22)};
  }

  h3 {
    margin: 0;
    font-size: ${({ theme }) => theme.rem(18)};
    font-weight: 600;
  }

  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.gray90};
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.gray20};
  }

  :root {
    --toastify-font-family: ${FONT_FAMILY};
  }


  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

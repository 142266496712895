import { HOUR_24 } from "@gearbox-protocol/sdk-gov";
import { BatchInfo } from "features/batches/batches.types";
import { NewTransaction } from "features/createTransactions/createTransactions.types";
import { BatchBundle } from "types/batches";
import { Cmd } from "types/cmd";
import { Transaction } from "types/transactions";

import { encodeTransaction } from "./transactions";

function isBatchBundleArray(
  array: BatchBundle[] | BatchInfo[],
): array is BatchBundle[] {
  return array.length === 0 || array[0].hasOwnProperty("safeBatches");
}

export const prepareBatches = (
  rawBatches: BatchBundle[] | BatchInfo[],
  queuedTransactions: Transaction[],
): BatchInfo[] => {
  if (rawBatches.length === 0) {
    return [];
  }

  const safeBatches = !isBatchBundleArray(rawBatches)
    ? rawBatches
    : rawBatches
        .map(bundle =>
          bundle.safeBatches.map(batch => ({ ...batch, type: bundle.type })),
        )
        .flat()
        // TODO: it's temporary
        .filter(batch => !batch.meta.name.includes("router.router.setup"))
        .map(batch => ({
          type: batch.type,
          chainId: batch.chainId,
          eta: Number(batch.transactions[0]?.contractInputsValues?.eta),
          safeAddress: batch.safeAddress,
          meta: batch.meta,
          transactions: batch.transactions.map(tx => ({
            contractMethod: tx.contractMethod,
            encoded: encodeTransaction(tx),
          })),
        }));

  const isSignedBatches = safeBatches.map(({ transactions }) => {
    if (!queuedTransactions || transactions.length < 1) {
      return true;
    }

    if (transactions.length === 1) {
      return !!queuedTransactions.find(
        queuedTx =>
          queuedTx.transactions[0].to.toLowerCase() ===
            transactions[0].encoded.to.toLowerCase() &&
          (queuedTx.transactions[0]?.data ?? "").toLowerCase() ===
            transactions[0].encoded.data.toLowerCase(),
      );
    }

    return !!queuedTransactions.find(
      queuedTx =>
        queuedTx.transactions[0].to.toLowerCase() ===
          transactions[0].encoded.to.toLowerCase() &&
        (queuedTx.transactions[0]?.data ?? "").toLowerCase() ===
          transactions[0].encoded.data.toLowerCase() &&
        queuedTx.transactions[1].to.toLowerCase() ===
          transactions[1].encoded.to.toLowerCase() &&
        (queuedTx.transactions[1]?.data ?? "").toLowerCase() ===
          transactions[1].encoded.data.toLowerCase(),
    );
  });

  const batches = safeBatches.map((batch, index) => {
    const isSigned = isSignedBatches[index];

    const isPreviousSigned =
      index === 0
        ? true
        : isSignedBatches
            .slice(0, index)
            .reduce(
              (accumulator: boolean, currentValue: boolean) =>
                accumulator && currentValue,
              true,
            );

    return {
      ...batch,
      id: index,
      isSigned,
      isPreviousSigned,
    };
  });

  return batches;
};

export const prepareBatchData = (tx: NewTransaction) => {
  switch (tx.cmd.id) {
    case Cmd.setTokenLimit:
      return {
        pool: tx.pool?.address,
        token: tx.token?.label,
        limit: Number(tx.limit),
      };
    case Cmd.addCollateralToken:
      return {
        creditManagerName: tx.cm?.label,
        token: tx.token?.label,
        ltHuman: Number(tx.threshold),
      };
    case Cmd.rampLiquidationThreshold:
      return {
        creditManagerName: tx.cm?.label,
        token: tx.token?.label,
        liquidationThresholdFinal: Number(tx.threshold),
        rampStart: tx.rampStart,
        rampDuration: (tx.rampDuration ?? 0) * HOUR_24,
      };
    case Cmd.setMinDebtLimit:
      return {
        creditManagerName: tx.cm?.label,
        limit: Number(tx.limit),
      };
    case Cmd.setMaxDebtLimit:
      return {
        creditManagerName: tx.cm?.label,
        limit: Number(tx.limit),
      };

    case Cmd.forbidBorrowing:
      return {
        creditManagerName: tx.cm?.label,
      };

    case Cmd.setFees:
      return {
        creditManagerName: tx.cm?.label,
        feeInterest: Number(tx.feeInterest) * 100,
        feeLiquidation: Number(tx.feeLiquidation) * 100,
        liquidationPremium: Number(tx.liquidationPremium) * 100,
        feeLiquidationExpired: Number(tx.feeLiquidationExpired) * 100,
        liquidationPremiumExpired: Number(tx.liquidationPremiumExpired) * 100,
      };

    default:
      return undefined;
  }
};

export const prepareBatchRequest = (transactions: NewTransaction[]) =>
  transactions.map(tx => ({
    cmd: tx.cmd.cmd,
    data: prepareBatchData(tx),
  }));

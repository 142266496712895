import { css, styled } from "styled-components";
import { CssMixin } from "theme";

export type InputColorTheme = "filledDark" | "filledLight" | "outlined";

const BgDark = css`
  background-color: ${({ theme }) => theme.colors.gray20};
  border: 1px solid ${({ theme }) => theme.colors.gray30};
`;

const BgDarkHover = css`
  background-color: ${({ theme }) => theme.colors.gray30};
  border-color: ${({ theme }) => theme.colors.gray50};
`;

const BgDarkFocus = css`
  background-color: ${({ theme }) => theme.colors.gray20};
  border-color: ${({ theme }) => theme.colors.gray20};
  outline: 2px solid ${({ theme }) => theme.colors.mainPrimary};
`;

const BgDarkError = css`
  border-color: ${({ theme }) => theme.colors.additionalAlert};
  outline: 2px solid ${({ theme }) => theme.colors.additionalAlert};
`;

const InputDark = css`
  color: ${({ theme: { colors } }) => colors.textPrimary};

  &::placeholder {
    color: ${({ theme }) => theme.colors.textSecondary};
  }
`;

const BgLight = css`
  background-color: ${({ theme }) => theme.colors.textPrimary};
  border: 1px solid ${({ theme }) => theme.colors.textPrimary};
`;

const BgLightHover = css`
  background-color: ${({ theme }) => theme.colors.textPrimary};
  border-color: ${({ theme }) => theme.colors.mainPrimary};
`;

const BgLightFocus = css`
  background-color: ${({ theme }) => theme.colors.textPrimary};
  border-color: ${({ theme }) => theme.colors.textPrimary};
  outline: 2px solid ${({ theme }) => theme.colors.mainPrimary};
`;

const BgLightError = css`
  border-color: ${({ theme }) => theme.colors.additionalAlert};
  outline: 2px solid ${({ theme }) => theme.colors.additionalAlert};
`;

const InputLight = css`
  color: ${({ theme: { colors } }) => colors.textDark};

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray100};
  }
`;

const BgOutlined = css`
  background-color: ${({ theme }) => theme.colors.transparent};
  border: 1px solid ${({ theme }) => theme.colors.gray40};
`;

const BgOutlinedHover = css`
  background-color: ${({ theme }) => theme.colors.transparent};
  border-color: ${({ theme }) => theme.colors.gray90};
`;

const BgOutlinedFocus = css`
  background-color: ${({ theme }) => theme.colors.textPrimary};
  border-color: ${({ theme }) => theme.colors.textPrimary};
`;

const BgOutlinedError = css`
  border-color: ${({ theme }) => theme.colors.additionalAlert};
`;

const InputOutlined = css`
  color: ${({ theme: { colors } }) => colors.textDark};

  &::placeholder {
    color: ${({ theme }) => theme.colors.textPrimary};
  }
`;

const THEMES: Record<
  InputColorTheme,
  {
    bg: CssMixin;
    focus: CssMixin;
    hover: CssMixin;
    error: CssMixin;
    input: CssMixin;
  }
> = {
  filledDark: {
    bg: BgDark,
    hover: BgDarkHover,
    focus: BgDarkFocus,
    input: InputDark,
    error: BgDarkError,
  },
  filledLight: {
    bg: BgLight,
    hover: BgLightHover,
    focus: BgLightFocus,
    input: InputLight,
    error: BgLightError,
  },
  outlined: {
    bg: BgOutlined,
    hover: BgOutlinedHover,
    focus: BgOutlinedFocus,
    input: InputOutlined,
    error: BgOutlinedError,
  },
};

export type InputSize = "xs" | "sm" | "md" | "lg";

const BgSm = css`
  border-radius: 6px;
`;

const BgMd = css`
  border-radius: 12px;
`;

const BgLg = css`
  border-radius: 12px;
`;

const BG_SIZES: Record<InputSize, CssMixin> = {
  xs: BgSm,
  sm: BgSm,
  md: BgMd,
  lg: BgLg,
};

interface InputTheme {
  $colorTheme: InputColorTheme;
  $size: InputSize;
}

export const InputBg = styled.div<InputTheme>`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  pointer-events: none;
  outline-offset: -2px;
  ${({ $colorTheme }) => THEMES[$colorTheme].bg}

  ${({ $size }) => BG_SIZES[$size]}
`;

const InputXs = css`
  padding: 6px 10px;
  height: auto;
`;

const InputSm = css`
  padding: 7px 12px;
  height: 32px;
`;

const InputMd = css`
  padding: 14px 11px;
  height: 40px;
`;

const InputLg = css`
  padding: 14px 13px;
  height: 44px;
`;

const INPUT_SIZES: Record<InputSize, CssMixin> = {
  xs: InputXs,
  sm: InputSm,
  md: InputMd,
  lg: InputLg,
};

export const StyledInput = styled.input<InputTheme>`
  width: 100%;
  font-size: ${({ theme }) => theme.rem(14)};
  line-height: ${({ theme }) => theme.rem(17)};
  ${({ $colorTheme }) => THEMES[$colorTheme].input}
  background-color: transparent;
  border: none;
  outline: none;
  font-feature-settings: normal;
  ${({ $size }) => INPUT_SIZES[$size]}

  &::placeholder {
    opacity: 1;
    font-feature-settings: normal;
  }

  &::-ms-clear,
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
`;

export const InputCard = styled.label<InputTheme>`
  position: relative;
  z-index: 0;
  width: 100%;
  ${({ theme }) => theme.flexRow.rowNoWrap}
  align-items: center;

  &:hover ${InputBg} {
    ${({ $colorTheme }) => THEMES[$colorTheme].hover}
  }

  /* stylelint-disable-next-line selector-type-no-unknown */
  ${StyledInput}:focus ~ ${InputBg} {
    ${({ $colorTheme }) => THEMES[$colorTheme].focus}
  }

  /* stylelint-disable-next-line rule-empty-line-before */
  ${StyledInput}[data-error="true"] ~ ${InputBg} {
    ${({ $colorTheme }) => THEMES[$colorTheme].error}
  }
`;

type WrapPosition = "left" | "right";

const LeftIconWrap = css`
  padding-right: 0;
`;

const RightIconWrap = css`
  padding-left: 0;
`;

const WRAP_POSITION: Record<WrapPosition, CssMixin> = {
  left: LeftIconWrap,
  right: RightIconWrap,
};

const WrapFilledDark = css`
  color: ${({ theme }) => theme.colors.gray110};
`;

const WrapFilledLight = css`
  color: ${({ theme }) => theme.colors.gray50};
`;

const WrapOutlined = css`
  color: ${({ theme }) => theme.colors.gray110};
`;

const WRAP_COLOR_THEMES: Record<InputColorTheme, CssMixin> = {
  filledDark: WrapFilledDark,
  filledLight: WrapFilledLight,
  outlined: WrapOutlined,
};

interface IconWrapProps {
  $colorTheme: InputColorTheme;
  $position: WrapPosition;
  $size: InputSize;
  $hasPointer: boolean;
}

export const IconWrap = styled.div<IconWrapProps>`
  width: min-content;
  height: min-content;
  font-size: ${({ theme }) => theme.rem(14)};
  line-height: 100%;
  color: ${({ theme }) => theme.colors.gray110};

  cursor: ${({ $hasPointer }) => ($hasPointer ? "pointer" : "unset")};

  ${({ $size }) => INPUT_SIZES[$size]}
  ${({ $position }) => WRAP_POSITION[$position]};
  ${({ $colorTheme }) => WRAP_COLOR_THEMES[$colorTheme]};
`;

export const EditRoot = styled.form`
  position: relative;
  width: 100%;
  ${({ theme }) => theme.flexRow.rowNoWrap}
  justify-content: flex-end;
  align-items: center;
`;

const EditInputXs = css`
  height: auto;
`;

const EditInputSm = css`
  height: 32px;
`;

const EditInputMd = css`
  height: 40px;
`;

const EditInputLg = css`
  height: 44px;
`;

const EDIT_INPUT_SIZES: Record<InputSize, CssMixin> = {
  xs: EditInputXs,
  sm: EditInputSm,
  md: EditInputMd,
  lg: EditInputLg,
};

interface EditLabelProps {
  $size: InputSize;
}

export const EditLabel = styled.div<EditLabelProps>`
  display: flex;
  width: 100%;
  ${({ $size }) => EDIT_INPUT_SIZES[$size]}

  justify-content: flex-end;
  align-items: center;

  cursor: pointer;
`;

export const Root = styled.div`
  width: 100%;
`;

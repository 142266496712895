import { useSafeAppsSDK } from "@safe-global/safe-apps-react-sdk";
import { Loader, StyledButton } from "components/elements";
import { refetchBatchList } from "features/batches/batchesSlice";
import { useAppDispatch } from "hooks";
import { useAccount } from "wagmi";

export interface SignButtonProps {
  loadingState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  transactions: { to: string; value: string; data: `0x${string}` }[];
  disabled?: boolean;
}

export function SignButton({
  loadingState,
  transactions,
  disabled,
}: SignButtonProps) {
  const { chainId, address } = useAccount();
  const { sdk } = useSafeAppsSDK();

  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = loadingState;

  return (
    <StyledButton
      $color="primary"
      disabled={isLoading || disabled}
      onClick={async () => {
        if (transactions) {
          setIsLoading(true);
          try {
            await sdk.txs.send({
              txs: transactions,
            });
            await dispatch(refetchBatchList({ safeAddress: address, chainId }));
          } catch (e) {
            console.log(`Error while sending transactions ${e}`);
          } finally {
            setIsLoading(false);
          }
        }
      }}
    >
      {isLoading ? <Loader /> : "Sign"}
    </StyledButton>
  );
}

import { configureStore } from "@reduxjs/toolkit";
import batchesReducer from "features/batches/batchesSlice";
import createTransactionsReducer from "features/createTransactions/createTransactionsSlice";
import gearboxStateReducer from "features/gearboxState/gearboxStateSlice";

export const store = configureStore({
  reducer: {
    batches: batchesReducer,
    gearboxState: gearboxStateReducer,
    createTransactions: createTransactionsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

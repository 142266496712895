import { Col } from "components/elements";
import { Account } from "components/modules";

export function Home() {
  return (
    <Col $gap={16}>
      <Account />
    </Col>
  );
}

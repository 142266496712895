import { sleep } from "@gearbox-protocol/sdk-gov";
import { Col, Loader, Row, StyledButton } from "components/elements";
import { selectGearboxState } from "features/gearboxState/gearboxStateSlice";
import { useAppSelector } from "hooks";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { CmInfo, NewTxFormFormValues } from "types/transactions";
import { getCmDebtLimit } from "utils/state";

import { CmField } from "../NewTxFormFields/CmField";
import { NumberField } from "../NewTxFormFields/NumberField";

export function SetDebtLimitModule({
  limitType,
}: {
  limitType: "min" | "max";
}) {
  const { data, status } = useAppSelector(selectGearboxState);

  const {
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext<NewTxFormFormValues>();

  const watchedValue = watch();

  const disabled = useMemo(
    () =>
      !watchedValue.cmd || !watchedValue.cm || watchedValue.limit === undefined,
    [watchedValue.cmd, watchedValue.cm, watchedValue.limit],
  );

  if (status !== "succeeded" || !data) {
    return (
      <Col $p={16}>
        <Loader />
      </Col>
    );
  }

  return (
    <>
      <CmField
        onChangeCallback={async (cm: CmInfo) => {
          const limit = getCmDebtLimit({
            state: data,
            cm,
            limitType,
          });

          setValue("limit", limit);
          await sleep(1);
          await trigger("limit");
        }}
      />

      <NumberField
        name="limit"
        title={`New ${limitType === "min" ? "Min" : "Max"} Debt Limit`}
        validate={value => {
          const limit = getCmDebtLimit({
            state: data,
            cm: watchedValue.cm,
            limitType,
          });

          if (limit !== undefined && limit === Number(value)) {
            return `New ${limitType} debt limit can not be the same`;
          }

          return true;
        }}
      />

      <Row $noGutters $justify="flex-end">
        <StyledButton
          type="submit"
          disabled={disabled || Object.keys(errors).length > 0}
        >
          Add Tx
        </StyledButton>
      </Row>
    </>
  );
}

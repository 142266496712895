import { MediaWidthList } from "./types";

export const MEDIA_WIDTHS: MediaWidthList = {
  extraSmall: 500,
  small: 720,
  medium: 960,
  large: 1120,
};

export const FONT_SIZE = 16;
export const SIZE_STEP = 4;

export const MAX_COL = 12;

export const FONT_FAMILY =
  "'Rubik', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif";

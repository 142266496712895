import {
  Col,
  DialogModalContainer,
  Loader,
  Row,
  StyledButton,
  Title,
  Typography,
} from "components/elements";
import { useState } from "react";

import { SignButton, SignButtonProps } from "./SignButton";

interface ConfirmDialog extends SignButtonProps {
  name: string;
}

export function ConfirmDialog({
  loadingState,
  transactions,
  name,
}: ConfirmDialog) {
  const [isLoading] = loadingState;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <StyledButton
        $color="primary"
        disabled={isLoading}
        onClick={() => setIsOpen(true)}
      >
        {isLoading ? <Loader /> : "Sign"}
      </StyledButton>

      <DialogModalContainer
        show={isOpen}
        onClickOutside={() => setIsOpen(false)}
      >
        <Col $noGutters $align="space-between" $gap={4}>
          <Title>{name}</Title>
          <Col $noGutters $align="space-between" $gap={20}>
            <Col $justify="center">
              <Typography>You didn&apos;t signed previous batch.</Typography>
              <Typography>Are you sure you want to sign {name}?</Typography>
            </Col>

            <Row $justify="flex-end" $noGutters>
              <Row $noGutters $gap={4}>
                <StyledButton
                  $color="neutral"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  Cancel
                </StyledButton>
                <SignButton
                  loadingState={loadingState}
                  transactions={transactions}
                />
              </Row>
            </Row>
          </Col>
        </Col>
      </DialogModalContainer>
    </>
  );
}

import { Input, Label } from "./styles";

export interface ToggleProps {
  checked: boolean;
  disabled?: boolean;
  onChange?: (nextState: boolean) => void;
}

export function Toggle({ checked, onChange, disabled }: ToggleProps) {
  return (
    <Label data-checked={checked} data-disabled={disabled}>
      <Input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={() => onChange?.(!checked)}
      />
    </Label>
  );
}

import {
  createSelector,
  createSelectorCreator,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "store";

import { NewTransaction, TransactionsState } from "./createTransactions.types";

const initialState: TransactionsState = {
  transactions: [],
};

const createTransactionsSlice = createSlice({
  name: "createTransactions",
  initialState,
  reducers: {
    addTransaction: (state, action: PayloadAction<NewTransaction>) => {
      state.transactions.push(action.payload);
    },
    removeTransaction: (state, action: PayloadAction<number>) => {
      state.transactions.splice(action.payload, 1);
    },
  },
});

export default createTransactionsSlice.reducer;

export const { addTransaction, removeTransaction } =
  createTransactionsSlice.actions;

export const selectCreateTransactions = createSelector(
  [(state: RootState) => state.createTransactions],
  createTransactions => ({
    transactions: createTransactions.transactions,
  }),
);

export const selectCreateTransaction = createSelector(
  [
    (state: RootState) => state.createTransactions.transactions,
    (_, index: number | undefined) => index,
  ],
  (transactions, index) => {
    const transactionSerialized =
      index !== undefined ? transactions[index] : undefined;
    return {
      transaction: transactionSerialized,
    };
  },
);

import { Col } from "components/elements";
import { Batches, Constructor } from "components/modules";
import {
  getGearboxState,
  selectGearboxState,
} from "features/gearboxState/gearboxStateSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect } from "react";

import { selectFullBatchesInfo } from "../features/batches/batchesSlice";

export function Create() {
  const { status: stateStatus } = useAppSelector(selectGearboxState);
  const { status: batchesStatus } = useAppSelector(selectFullBatchesInfo);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (stateStatus === "idle") {
      dispatch(getGearboxState()).catch(e => {
        console.log("Failed to load Gearbox State: ", e);
      });
    }
  }, [dispatch]);

  return (
    <Col $gap={16}>
      <Constructor />

      {batchesStatus !== "idle" && <Batches />}
    </Col>
  );
}

import { css, styled } from "styled-components";
import { CssMixin } from "theme";

const Primary = css`
  color: ${({ theme }) => theme.colors.textPrimary};
  background-color: ${({ theme }) => theme.colors.mainPrimary};
  border: 1px solid ${({ theme }) => theme.colors.mainPrimary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.mainAccent};
    border-color: ${({ theme }) => theme.colors.mainAccent};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.textSecondary};
    background-color: ${({ theme }) => theme.colors.gray20};
    border-color: ${({ theme }) => theme.colors.gray20};
  }
`;

const White = css`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.pageBg};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.white};
  box-shadow: 0 1px 1px 0 rgba(0 0 0 / 15%);

  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 3px 3px 0 rgba(0 0 0 / 25%);
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.gray80};
    background-color: ${({ theme }) => theme.colors.gray110};
    border-color: ${({ theme }) => theme.colors.gray110};
    box-shadow: 0 1px 1px 0 rgba(0 0 0 / 15%);
  }
`;

const Outlined = css`
  color: ${({ theme }) => theme.colors.textPrimary};
  background-color: ${({ theme }) => theme.colors.transparent};
  border: 1px solid ${({ theme }) => theme.colors.gray40};

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray60};
    border-color: ${({ theme }) => theme.colors.gray60};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.textSecondary};
    background-color: ${({ theme }) => theme.colors.gray20};
    border-color: ${({ theme }) => theme.colors.gray20};
  }
`;

const Neutral = css`
  color: ${({ theme }) => theme.colors.textPrimary};
  background-color: ${({ theme }) => theme.colors.gray60};
  border: 1px solid ${({ theme }) => theme.colors.gray60};

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray90};
    border-color: ${({ theme }) => theme.colors.gray90};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.textSecondary};
    background-color: ${({ theme }) => theme.colors.gray20};
    border-color: ${({ theme }) => theme.colors.gray20};
  }
`;

const Success = css`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.textDark};
  background: ${({ theme }) => theme.colors.additionalSuccess};
  border: solid 1px ${({ theme }) => theme.colors.additionalSuccess};

  &:hover {
    background: #b3ffc8;
    border: solid 1px #b3ffc8;
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.textSecondary};
    background-color: ${({ theme }) => theme.colors.gray20};
    border: solid 1px ${({ theme }) => theme.colors.gray20};
  }
`;

type ButtonColors = "primary" | "outlined" | "neutral" | "white" | "success";

const COLOR_THEME: Record<ButtonColors, CssMixin> = {
  primary: Primary,
  outlined: Outlined,
  neutral: Neutral,
  white: White,
  success: Success,
};

const Compact = css`
  width: fit-content;
`;

const Wide = css`
  width: 100%;
`;

type ButtonWidth = "compact" | "wide";

const WIDTH_THEME: Record<ButtonWidth, CssMixin> = {
  compact: Compact,
  wide: Wide,
};

const Sm = css`
  padding: 0 12px;
  height: 28px;

  font-size: ${({ theme }) => theme.rem(12)};
  line-height: ${({ theme }) => theme.rem(14)};

  border-radius: 6px;
`;

const Md = css`
  padding: 0 16px;
  height: 40px;

  font-size: ${({ theme }) => theme.rem(15)};
  line-height: ${({ theme }) => theme.rem(18)};

  border-radius: 12px;
`;

const Lg = css`
  padding: 0 16px;
  height: 44px;

  font-size: ${({ theme }) => theme.rem(16)};
  line-height: ${({ theme }) => theme.rem(19)};

  border-radius: 12px;
`;

export type ButtonSize = "sm" | "md" | "lg";

const SIZE_THEME: Record<ButtonSize, CssMixin> = {
  sm: Sm,
  md: Md,
  lg: Lg,
};

const SquareSm = css`
  padding: 0;
  display: inline-flex;
  width: 28px;

  ${({ theme }) => theme.flexRow.centered}
`;

const SquareMd = css`
  padding: 0;
  display: inline-flex;
  width: 40px;

  ${({ theme }) => theme.flexRow.centered}
`;

const SquareLg = css`
  padding: 0;
  display: inline-flex;
  width: 44px;

  ${({ theme }) => theme.flexRow.centered}
`;

const SQUARE_SIZE_THEME: Record<ButtonSize, CssMixin> = {
  sm: SquareSm,
  md: SquareMd,
  lg: SquareLg,
};

type ButtonTheme = "normal" | "icon";

const SmIcon = css`
  padding: 0;
  width: 28px;
  max-width: 28px;
`;

const MdIcon = css`
  padding: 0;
  width: 40px;
  max-width: 40px;
`;

const LgIcon = css`
  padding: 0;
  width: 44px;
  max-width: 44px;
`;

const ICON_THEME: Record<ButtonSize, CssMixin> = {
  sm: SmIcon,
  md: MdIcon,
  lg: LgIcon,
};

export interface StyledButtonProps {
  $width?: ButtonWidth;
  $color?: ButtonColors;
  $size?: ButtonSize;
  $square?: boolean;

  $sizeTheme?: ButtonTheme;
}

const StyledButtonMixin = css<StyledButtonProps>`
  font-weight: 400;

  ${({ $width = "compact" }) => WIDTH_THEME[$width]}
  ${({ $size = "md" }) => SIZE_THEME[$size]}
  ${({ $size = "md", $square }) => ($square ? SQUARE_SIZE_THEME[$size] : "")}
  ${({ $color = "primary" }) => COLOR_THEME[$color]}

  ${({ $size = "md", $sizeTheme }) =>
    $sizeTheme === "icon" ? ICON_THEME[$size] : ""}


  &:disabled {
    cursor: not-allowed;
  }
`;

export const StyledButton = styled.button<StyledButtonProps>`
  ${StyledButtonMixin}
`;

import { MainLayout } from "components/layouts";
import { Create, Home, Upload } from "pages";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CREATE, HOME_PATH, UPLOAD } from "routes";

export default function App() {
  return (
    <BrowserRouter>
      <MainLayout>
        <Routes>
          {/* Home page */}
          <Route path={HOME_PATH} element={<Home />} />
          <Route path={UPLOAD} element={<Upload />} />
          <Route path={CREATE} element={<Create />} />
        </Routes>
      </MainLayout>
    </BrowserRouter>
  );
}

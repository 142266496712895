import { styled } from "styled-components";

const PADDING = 2;
const TOGGLE_SIZE = 16;

export const Label = styled.label`
  position: relative;
  display: inline-flex;
  width: 32px;
  height: 20px;

  align-items: center;

  background-color: ${({ theme }) => theme.colors.gray60};
  border-radius: 21px;

  transition: background-color 100ms ease;

  cursor: pointer;

  &::after {
    position: absolute;
    top: ${PADDING}px;
    right: calc(100% - ${TOGGLE_SIZE}px - ${PADDING}px);

    display: block;
    width: ${TOGGLE_SIZE}px;
    height: ${TOGGLE_SIZE}px;

    content: "";

    background-color: ${({ theme }) => theme.colors.textPrimary};
    border-radius: 50%;
    box-shadow: 0 2px 2px rgba(0 0 0 / 25%);

    transition: right 100ms ease;
  }

  &[data-checked="true"] {
    background: ${({ theme }) => theme.colors.mainAccent};

    &::after {
      right: ${PADDING}px;
    }
  }

  &[data-disabled="true"] {
    background-color: ${({ theme }) => theme.colors.gray60};
    cursor: not-allowed;

    &::after {
      background-color: ${({ theme }) => theme.colors.gray100};
    }
  }
`;

export const Input = styled.input`
  width: 0;
  height: 0;
  opacity: 0;
  cursor: pointer;
`;

import axios from "axios";
import {
  SafeTransactionServiceResponse,
  SafeTx,
  Transaction,
} from "types/transactions";
import { encodeFunctionData } from "viem";

import { json_parse } from "./bigint-serializer";
import { TRANSACTION_SERVICE_URL } from "./constants";

export const encodeTransaction = (tx: SafeTx) => {
  const abi = [{ ...tx.contractMethod, outputs: [], type: "function" }];
  const functionName = tx.contractMethod.name;

  const args = tx.contractMethod.inputs.map(input => {
    const arg = tx.contractInputsValues[input.name];

    try {
      return json_parse(arg);
    } catch {
      return arg;
    }
  });

  const data = encodeFunctionData({
    abi,
    functionName,
    args,
  });

  return {
    to: tx.to,
    value: tx.value,
    data,
  };
};

export const getSafeTransactionList = async (
  chainId: number | string,
  safeAddress: `0x${string}`,
) => {
  const baseUrl = TRANSACTION_SERVICE_URL[Number(chainId)];

  const { data } = await axios.get<SafeTransactionServiceResponse>(
    `${baseUrl}api/v1/safes/${safeAddress}/all-transactions/?queued=true&executed=false`,
  );

  const result = data.results.map(({ safeTxHash, dataDecoded, data, to }) => {
    // Transaction has only one action
    if (!dataDecoded?.parameters || dataDecoded.parameters.length > 1) {
      return {
        safeTxHash,
        transactions: [{ data, to }],
      };
    }

    if (dataDecoded.parameters.length === 0) {
      return {
        safeTxHash,
        transactions: [],
      };
    }

    // Transaction has multiple actions
    if (dataDecoded.parameters.length === 1) {
      const transactions = dataDecoded.parameters?.[0].valueDecoded.map(
        ({ to, data }) => ({ to, data }),
      );

      return {
        safeTxHash,
        transactions,
      };
    }

    return {
      safeTxHash,
      transactions: [
        {
          data,
          to,
        },
      ],
    };
  });

  return result as Transaction[];
};

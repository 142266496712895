import { createGlobalStyle, styled } from "styled-components";

export const NoOverflow = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const DEFAULT_Z_INDEX = 200;

interface ZIndexProps {
  $zIndex?: number;
}

export const ModalDiv = styled.div<ZIndexProps>`
  position: fixed;
  z-index: ${({ $zIndex = DEFAULT_Z_INDEX }) => $zIndex + 5};
  top: 0;
  left: 0;
  display: flex;
  overflow: auto;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

interface ModalContentWrapperProps {
  $xsCentered?: boolean;
}

export const ModalContentWrapper = styled.div<ModalContentWrapperProps>`
  position: relative;
  margin: auto;
  padding: 20px;

  ${({ theme }) => theme.mediaWidth.small`
    padding: 0;
  `};

  ${({ theme, $xsCentered }) => theme.mediaWidth.extraSmall`
    margin: 0;
    width: 100%;

    ${
      $xsCentered
        ? `
          min-height: 100vh;
          ${theme.flexRow.rowNoWrap}
          ${theme.flexRow.centered}
        `
        : ""
    }

  `};
`;

export const DarkenDiv = styled.div<ZIndexProps>`
  position: fixed;
  z-index: ${({ $zIndex = DEFAULT_Z_INDEX }) => $zIndex + 4};
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  opacity: 0.3;
  background-color: ${({ theme }) => theme.colors.black};
`;

import {
  Col,
  Description,
  LinkText,
  Row,
  Typography,
} from "components/elements";
import { GearIcon } from "components/icons";
import { useAutoConnect } from "hooks/safe/useAutoConnect";
import { shortenAddress } from "utils/misc";
import { useAccount } from "wagmi";

import { Layout, LayoutTitle, Sidebar } from "./styles";

const Logo = () => (
  <Row $align="center" $mb={8} $noGutters>
    <GearIcon />
    <LayoutTitle>Gearbox Mgmt</LayoutTitle>
  </Row>
);

export function MainLayout({ children }: { children: JSX.Element }) {
  useAutoConnect();
  const { address, chain } = useAccount();

  return (
    <Layout $maxW={1400} $noGutters $wrap={false}>
      {address ? (
        <>
          <Sidebar>
            <Logo />

            <Col $h={"100%"} $minW={240} $gap={12}>
              <Col $noGutters>
                <Typography>
                  <b>Chain:</b> {chain?.name}
                </Typography>
                <Typography>
                  <b>Address:</b> {shortenAddress(address)}
                </Typography>
              </Col>

              <Col $noGutters $gap={2}>
                <LinkText href={`/create`}>
                  <Typography>Tx Constructor</Typography>
                </LinkText>
                <LinkText href={`/upload`}>
                  <Typography>Upload JSONs</Typography>
                </LinkText>
              </Col>
            </Col>
          </Sidebar>

          <Col $maxW={1024} $w={"100%"}>
            {children}
          </Col>
        </>
      ) : (
        <Col $noGutters>
          <Logo />

          <Col>
            <Typography>You are not connected</Typography>
            <Description>
              {"Open this app in Safe {Wallet} Apps to connect"}
            </Description>
          </Col>
        </Col>
      )}
    </Layout>
  );
}

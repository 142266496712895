import { Typography } from "components/elements";
import { IconPending } from "components/icons";

import { LoaderIconWrap, LoaderRoot } from "./styles";

export function Loader() {
  return (
    <LoaderRoot>
      <LoaderIconWrap>
        <IconPending />
      </LoaderIconWrap>

      <Typography $weight="bold">Loading...</Typography>
    </LoaderRoot>
  );
}

import { styled } from "styled-components";

export const LoaderRoot = styled.div`
  ${({ theme }) => theme.flexRow.rowWrap}
  ${({ theme }) => theme.flexRow.centered}
`;

export const LoaderIconWrap = styled.div`
  margin-right: 8px;
  overflow: hidden;
`;

import { css, styled } from "styled-components";
import { CssMixin } from "theme";

const Primary = css`
  color: ${({ theme }) => theme.colors.textPrimary};
  background-color: ${({ theme }) => theme.colors.mainPrimary};
  border: 1px solid ${({ theme }) => theme.colors.mainPrimary};
`;

const White = css`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.pageBg};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.white};
  box-shadow: 0 1px 1px 0 rgba(0 0 0 / 15%);
`;

const Outlined = css`
  color: ${({ theme }) => theme.colors.textPrimary};
  background-color: ${({ theme }) => theme.colors.transparent};
  border: 1px solid ${({ theme }) => theme.colors.gray40};
`;

const Neutral = css`
  color: ${({ theme }) => theme.colors.textPrimary};
  background-color: ${({ theme }) => theme.colors.gray60};
  border: 1px solid ${({ theme }) => theme.colors.gray60};
`;

const Success = css`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.textDark};
  background: ${({ theme }) => theme.colors.additionalSuccess};
  border: solid 1px ${({ theme }) => theme.colors.additionalSuccess};
`;

type BadgeColors = "primary" | "outlined" | "neutral" | "white" | "success";

const COLOR_THEME: Record<BadgeColors, CssMixin> = {
  primary: Primary,
  outlined: Outlined,
  neutral: Neutral,
  white: White,
  success: Success,
};

const Compact = css`
  width: auto;
`;

const Wide = css`
  width: 100%;
`;

type BadgeWidth = "compact" | "wide";

const WIDTH_THEME: Record<BadgeWidth, CssMixin> = {
  compact: Compact,
  wide: Wide,
};

const Sm = css`
  padding: 0 12px;
  height: 28px;

  font-size: ${({ theme }) => theme.rem(12)};
  line-height: ${({ theme }) => theme.rem(14)};

  border-radius: 6px;
`;

const Md = css`
  padding: 0 16px;
  height: 40px;

  font-size: ${({ theme }) => theme.rem(15)};
  line-height: ${({ theme }) => theme.rem(18)};

  border-radius: 12px;
`;

const Lg = css`
  padding: 0 16px;
  height: 44px;

  font-size: ${({ theme }) => theme.rem(16)};
  line-height: ${({ theme }) => theme.rem(19)};

  border-radius: 12px;
`;

export type BadgeSize = "sm" | "md" | "lg";

const SIZE_THEME: Record<BadgeSize, CssMixin> = {
  sm: Sm,
  md: Md,
  lg: Lg,
};

const SquareSm = css`
  padding: 0;
  display: inline-flex;
  width: 28px;

  ${({ theme }) => theme.flexRow.centered}
`;

const SquareMd = css`
  padding: 0;
  display: inline-flex;
  width: 40px;

  ${({ theme }) => theme.flexRow.centered}
`;

const SquareLg = css`
  padding: 0;
  display: inline-flex;
  width: 44px;

  ${({ theme }) => theme.flexRow.centered}
`;

const SQUARE_SIZE_THEME: Record<BadgeSize, CssMixin> = {
  sm: SquareSm,
  md: SquareMd,
  lg: SquareLg,
};

export interface BadgeProps {
  $width?: BadgeWidth;
  $color?: BadgeColors;
  $size?: BadgeSize;
  $square?: boolean;
}

const BadgeMixin = css<BadgeProps>`
  font-weight: 400;

  ${({ $width = "compact" }) => WIDTH_THEME[$width]}
  ${({ $size = "md" }) => SIZE_THEME[$size]}
  ${({ $size = "md", $square }) => ($square ? SQUARE_SIZE_THEME[$size] : "")}
  ${({ $color = "primary" }) => COLOR_THEME[$color]}

  display: flex;
  align-items: center;
`;

export const Badge = styled.div<BadgeProps>`
  ${BadgeMixin}
`;

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import React, { useMemo, useState } from "react";
import { Colors } from "theme";

import { Col } from "../Col";
import { Dropdown, DropdownProps } from "../Dropdown";
import { FaIconStyled } from "../FaIcon";
import { Input } from "../Input";
import { InputColorTheme } from "../Input/styles";
import {
  DropdownBox,
  DropdownItem,
  DropdownMenu,
  DropdownText,
  iconSizes,
  IconWrap,
  Size,
} from "./styles";

interface BaseItem {
  label: string;
}

type DropdownItem = BaseItem | string;

export interface SearchDropdownProps<T extends DropdownItem>
  extends Omit<InputDropdownLabel<T>, "onFocus" | "opened"> {
  items: Array<T>;
  opens?: DropdownProps["opens"];
  onSelect: (item: T) => void;
  width?: string;
  contentWidth?: string;
  menuMinWidth?: string;
}

export function SearchDropdown<T extends DropdownItem>({
  items,

  opens = "left",
  width = "100%",
  contentWidth,
  menuMinWidth = "10rem",
  onSelect,
  value,
  ...labelProps
}: SearchDropdownProps<T>) {
  const [opened, setOpened] = useState(false);

  const handleOpenedClick = () => setOpened(!opened);
  const handleClosedClick = () => setOpened(false);

  const filteredItems = useMemo(() => {
    if (!value) {
      return items;
    }

    return items.filter(item => {
      const label = typeof item === "string" ? item : item.label;
      const valueLabel = typeof value === "string" ? value : value.label;

      return label.toLowerCase().includes(valueLabel.toLowerCase());
    });
  }, [value, items]);

  return (
    <Dropdown
      opened={opened}
      opens={opens}
      onClose={handleClosedClick}
      width={width}
      contentWidth={contentWidth}
      title={
        <InputDropdownLabel
          opened={opened}
          onFocus={handleOpenedClick}
          value={value}
          {...labelProps}
        />
      }
    >
      {items.length > 0 && (
        <DropdownBox>
          <DropdownMenu $minWidth={menuMinWidth}>
            {filteredItems.length > 0 ? (
              filteredItems.map(item => (
                <DropdownItem
                  onClick={() => onSelect(item)}
                  key={typeof item === "string" ? item : item.label}
                >
                  {typeof item === "string" ? item : item.label}
                </DropdownItem>
              ))
            ) : (
              <Col $justify="center" $noGutters $minH={"2rem"} $p={3}>
                <DropdownText>Nothing found</DropdownText>
              </Col>
            )}
          </DropdownMenu>
        </DropdownBox>
      )}
    </Dropdown>
  );
}

export interface InputDropdownLabel<T extends DropdownItem> {
  opened: boolean;

  placeholder: string;

  onBlur?: () => void;
  onFocus: () => void;

  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;

  value?: T;

  theme?: InputColorTheme;
  size?: Size;
  disabled?: boolean;
  hideIcon?: boolean;
  icon?: IconProp;

  iconColor?: keyof Colors;
  iconHoverColor?: keyof Colors;
}

export function InputDropdownLabel<T extends DropdownItem>({
  opened,

  onBlur,
  onFocus,
  onChange,

  value,
  placeholder,

  theme = "filledDark",
  size = "md",
  disabled = false,
  icon = faCaretDown,
  hideIcon = false,
  iconColor,
  iconHoverColor,
}: InputDropdownLabel<T>) {
  return (
    <Input
      disabled={disabled}
      colorTheme={theme}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      inputSize={size}
      endingIcon={
        !hideIcon && (
          <IconWrap $opened={opened}>
            <FaIconStyled
              $size={iconSizes[size].arrow}
              icon={icon}
              $disabledColor="textSecondary"
              data-disabled={disabled}
              $color={iconColor}
              $hoverColor={iconHoverColor}
            />
          </IconWrap>
        )
      }
      value={typeof value === "string" ? value : value?.label}
      placeholder={placeholder}
    />
  );
}

import { Col, Description, Row, Typography } from "components/elements";
import { Etherscan } from "components/icons";
import { shortenAddress } from "utils/misc";
import { useAccount } from "wagmi";

export function Contract({
  address,
  isDescription = false,
}: {
  address: string;
  isDescription?: boolean;
}) {
  const { chain } = useAccount();

  return (
    <Row $gap={2} $noGutters $align="center">
      {isDescription ? (
        <Description>{`(${shortenAddress(address)})`}</Description>
      ) : (
        <Typography $size="textSm">{shortenAddress(address)}</Typography>
      )}
      {!!chain?.blockExplorers && (
        <a
          href={`${chain.blockExplorers.default.url}/address/${address}#code`}
          target="_blank"
          style={{
            height: isDescription ? "16px" : "18px",
          }}
        >
          <Col $justify="center" $noGutters>
            <Etherscan verified size={isDescription ? 16 : 18} />
          </Col>
        </a>
      )}
    </Row>
  );
}

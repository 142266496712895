import { css, styled } from "styled-components";

const ItemBorder = css`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderSecondary};
`;

export const AccordionItemRoot = styled.li<{ $last: boolean }>`
  ${({ $last }) => ($last ? "" : ItemBorder)};
`;

const ActiveButton = css`
  background-color: transparent;
`;

export const AccordionButton = styled.button`
  padding: ${({ theme }) => theme.rem(20)} 0;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-size: ${({ theme }) => theme.rem(16)};
  font-weight: 700;
  text-align: left;
  color: ${({ theme }) => theme.colors.textPrimary};
  background-color: transparent;
  border: none;
  cursor: pointer;

  &[data-active="true"] {
    ${ActiveButton}
  }
`;

export const Control = styled.span<{ $active?: boolean }>`
  line-height: 0;
  transition: transform ease 0.1s;
  transform: rotate(${({ $active }) => ($active ? -180 : 0)}deg);
`;

export const AccordionDescriptionWrapper = styled.div<{
  $height: number;
  $animationEnded: boolean;
}>`
  overflow: hidden;
  height: ${({ $height, $animationEnded }) => {
    if ($height !== 0 && $animationEnded) {
      return "auto";
    }
    if ($height !== 0) {
      return `${$height}px`;
    }
    return "0";
  }};
  transition: height ease 0.2s;
`;

export const AccordionDescription = styled.div`
  padding-bottom: 20px;
  background-color: transparent;
`;

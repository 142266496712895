import {
  getDecimals,
  getNetworkType,
  sleep,
  SupportedToken,
  tokenDataByNetwork,
} from "@gearbox-protocol/sdk-gov";
import { Col, SearchDropdown, Typography } from "components/elements";
import { Controller, useFormContext } from "react-hook-form";
import { FormFildProps } from "types/forms";
import { NewTxFormFormValues, SupportedTokenInfo } from "types/transactions";
import { useAccount } from "wagmi";

export function TokenField({ onChangeCallback }: FormFildProps) {
  const { chainId } = useAccount();

  const { control, watch } = useFormContext<NewTxFormFormValues>();

  const tokens = tokenDataByNetwork[getNetworkType(chainId ?? 0)] ?? {};
  const tokenList: SupportedTokenInfo[] = (
    Object.keys(tokens) as SupportedToken[]
  )
    .map(label => ({
      address: tokens[label],
      label,
    }))
    .filter(({ address }) => address !== "0xNOT DEPLOYED")
    .map(token => ({
      ...token,
      decimals: getDecimals(token.label),
    }));

  if (!tokenList) {
    return <></>;
  }

  return (
    <Col $noGutters $gap={2} style={{}}>
      <Typography>Token</Typography>
      <Controller
        name={"token"}
        control={control}
        render={({ field }) => (
          <SearchDropdown
            items={tokenList}
            placeholder={"Select token"}
            onSelect={async i => {
              field.onChange(i);
              await onChangeCallback?.(i);
            }}
            onBlur={async () => {
              await sleep(100);
              const selectedToken = watch().token;

              if (
                selectedToken &&
                !tokenList.find(token => token.label === selectedToken.label)
              ) {
                field.onChange("");
              }
            }}
            onChange={event => field.onChange(event.currentTarget.value)}
            value={field.value}
          />
        )}
      />
    </Col>
  );
}

import "react-datepicker/dist/react-datepicker.css";

import { Col, Input, Typography } from "components/elements";
import dayjs, { unix } from "dayjs";
import DatePicker from "react-datepicker";
import { Controller, useFormContext } from "react-hook-form";
import {
  NewTxFormFormNumberValues,
  NewTxFormFormValues,
} from "types/transactions";

interface DateFieldProps {
  title: string;
  name: keyof NewTxFormFormNumberValues;
}

export function DateField({ name, title }: DateFieldProps) {
  const { control } = useFormContext<NewTxFormFormValues>();

  return (
    <Col $noGutters $gap={2} style={{}}>
      <Typography>{title}</Typography>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <DatePicker
            selected={
              field.value === undefined ? undefined : unix(field.value).toDate()
            }
            onChange={date => {
              field.onChange(dayjs(date).unix());
            }}
            showTimeInput
            dateFormat="dd/MM/yyyy HH:mm"
            customInput={<Input />}
            excludeScrollbar={true}
            onSelect={() => {}}
          />
        )}
      />
    </Col>
  );
}

import { Row } from "components/elements";
import { styled } from "styled-components";

export const Layout = styled(Row)`
  min-height: 100%;
  margin: ${({ theme }) => theme.rem(32)};
`;

export const LayoutTitle = styled.span`
  font-size: ${({ theme }) => theme.rem(24)};
  line-height: ${({ theme }) => theme.rem(28)};
  font-weight: bold;
  border-bottom: none;
`;

export const Sidebar = styled.div`
  position: sticky;
  min-height: calc(100vh - 64px);
  padding-right: 32px;
  border-right: 2px solid rgb(30, 41, 59);
  height: 100%;
  top: 32px;
`;

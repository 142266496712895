import {
  Contract,
  Description,
  Table,
  Td,
  Th,
  Tr,
  Typography,
} from "components/elements";
import { SafeMeta } from "types/batches";
import { ContractMethod } from "types/transactions";

interface TransactionTableProps {
  id: number;
  meta: SafeMeta;
  transactions: {
    contractMethod: ContractMethod;
    encoded: {
      to: string;
      value: string;
      data: `0x${string}`;
    };
  }[];
}

export function TransactionTable({
  meta,
  id,
  transactions,
}: TransactionTableProps) {
  return (
    <Table>
      <thead>
        <Tr>
          <Th $align="left">Action</Th>
          <Th $align="left">Target</Th>
        </Tr>
      </thead>
      <tbody>
        {transactions.slice(0, 5).map((tx, txIndex) => (
          <Tr
            key={`batch-${meta.name}-${id}-${txIndex}`}
            $last={txIndex === transactions.length - 1}
          >
            <Td
              style={{
                width: "240px",
              }}
            >
              <Typography $size="textSm">{tx.contractMethod.name}</Typography>
            </Td>
            <Td>
              <Contract address={tx.encoded.to} />
            </Td>
          </Tr>
        ))}
        {transactions.length > 5 && (
          <Tr $last>
            <Td>
              <Description>
                {transactions.length - 5} more transactions...
              </Description>
            </Td>
          </Tr>
        )}
      </tbody>
    </Table>
  );
}

import { sleep } from "@gearbox-protocol/sdk-gov";
import { Col, Loader, Row, StyledButton } from "components/elements";
import { selectGearboxState } from "features/gearboxState/gearboxStateSlice";
import { useAppSelector } from "hooks";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import {
  CmInfo,
  NewTxFormFormValues,
  SupportedTokenInfo,
} from "types/transactions";
import { getCmTokenLt } from "utils/state";

import { CmField } from "../NewTxFormFields/CmField";
import { DateField } from "../NewTxFormFields/DateField";
import { NumberField } from "../NewTxFormFields/NumberField";
import { TokenField } from "../NewTxFormFields/TokenField";

export function RampLiquidationThresholdModule() {
  const { data, status } = useAppSelector(selectGearboxState);

  const {
    watch,
    formState: { errors },
    setValue,
    trigger,
  } = useFormContext<NewTxFormFormValues>();
  const watchedValue = watch();

  const disabled = useMemo(
    () =>
      !watchedValue.cmd ||
      !watchedValue.cm ||
      !watchedValue.token ||
      watchedValue.rampDuration === undefined ||
      watchedValue.rampStart === undefined ||
      watchedValue.threshold === undefined,
    [
      watchedValue.cmd,
      watchedValue.cm,
      watchedValue.token,
      watchedValue.threshold,
      watchedValue.rampDuration,
      watchedValue.rampStart,
    ],
  );

  if (status !== "succeeded" || !data) {
    return (
      <Col $p={16}>
        <Loader />
      </Col>
    );
  }

  return (
    <>
      <CmField
        onChangeCallback={async (cm: CmInfo) => {
          const lt = getCmTokenLt({
            state: data,
            cm,
            token: watchedValue.token,
          });

          setValue("threshold", lt);
          await sleep(1);
          await trigger("threshold");
        }}
      />

      <TokenField
        onChangeCallback={async (token: SupportedTokenInfo) => {
          const lt = getCmTokenLt({
            state: data,
            cm: watchedValue.cm,
            token,
          });

          setValue("threshold", lt);
          await sleep(1);
          await trigger("threshold");
        }}
      />

      <NumberField
        name="threshold"
        title="Liquidation Threshold"
        endingIcon="%"
        validate={value => {
          const lt = getCmTokenLt({
            state: data,
            cm: watchedValue.cm,
            token: watchedValue.token,
          });

          if (lt !== undefined && lt === Number(value)) {
            return "New threshold can not be the same";
          }

          return true;
        }}
      />

      <DateField name="rampStart" title="Ramp Start" />

      <NumberField
        name="rampDuration"
        title="Ramp Duration (days)"
        placeholder="0"
      />

      <Row $noGutters $justify="flex-end">
        <StyledButton
          type="submit"
          disabled={disabled || Object.keys(errors).length > 0}
        >
          Add Tx
        </StyledButton>
      </Row>
    </>
  );
}

import { Col, StyledDropdown, Typography } from "components/elements";
import { selectGearboxState } from "features/gearboxState/gearboxStateSlice";
import { useAppSelector } from "hooks";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { FormFildProps } from "types/forms";
import { NewTxFormFormValues, PoolInfo } from "types/transactions";

export function PoolField({ onChangeCallback }: FormFildProps) {
  const { data } = useAppSelector(selectGearboxState);

  const { setValue, watch } = useFormContext<NewTxFormFormValues>();

  const watchedValue = watch();
  const selectedPool = useMemo(() => watchedValue.pool, [watchedValue.pool]);

  const poolList: PoolInfo[] | undefined = useMemo(
    () =>
      data?.poolState.map(({ pool }) => ({
        address: pool.address,
        label: pool.name,
        decimals: pool.decimals,
      })),
    [data],
  );

  if (!poolList) {
    return <></>;
  }

  return (
    <Col $noGutters $gap={2}>
      <Typography>Pool</Typography>
      <StyledDropdown
        items={poolList}
        label={selectedPool?.label ?? "Select pool"}
        onSelect={async i => {
          setValue("pool", i);
          await onChangeCallback?.(i);
        }}
      />
    </Col>
  );
}

import { styled } from "styled-components";
import { isString } from "utils/typeGuards";

export interface DivProps {
  $p?: string | number;
  $pl?: number;
  $pr?: number;
  $pt?: number;
  $pb?: number;

  $m?: string | number;
  $ml?: number;
  $mr?: number;
  $mt?: number;
  $mb?: number;

  $h?: string | number;
  $w?: string | number;

  $maxH?: string | number;
  $maxW?: string | number;

  $minH?: string | number;
  $minW?: string | number;
}

export const Div = styled.div<DivProps>`
  ${({ $w, theme }) =>
    $w ? `width:  ${isString($w) ? $w : theme.rem($w)};` : ""}
  ${({ $h, theme }) =>
    $h ? `height:  ${isString($h) ? $h : theme.rem($h)};` : ""}

  ${({ $minW, theme }) =>
    $minW ? `min-width:  ${isString($minW) ? $minW : theme.rem($minW)};` : ""}
  ${({ $minH, theme }) =>
    $minH ? `min-height:  ${isString($minH) ? $minH : theme.rem($minH)};` : ""}

  ${({ $maxW, theme }) =>
    $maxW ? `max-width:  ${isString($maxW) ? $maxW : theme.rem($maxW)};` : ""}
  ${({ $maxH, theme }) =>
    $maxH ? `max-height:  ${isString($maxH) ? $maxH : theme.rem($maxH)};` : ""}

  ${({ $p, theme }) =>
    $p ? `padding: ${isString($p) ? $p : theme.size($p)};` : ""};
  ${({ $pr, theme }) => ($pr ? `padding-right: ${theme.size($pr)};` : "")};
  ${({ $pl, theme }) => ($pl ? `padding-left:  ${theme.size($pl)};` : "")};
  ${({ $pt, theme }) => ($pt ? `padding-top: ${theme.size($pt)};` : "")};
  ${({ $pb, theme }) => ($pb ? `padding-bottom:  ${theme.size($pb)};` : "")};

  ${({ $m, theme }) =>
    $m ? `margin: ${isString($m) ? $m : theme.size($m)};` : ""};
  ${({ $mr, theme }) => ($mr ? `margin-right: ${theme.size($mr)};` : "")};
  ${({ $ml, theme }) => ($ml ? `margin-left:  ${theme.size($ml)};` : "")};
  ${({ $mt, theme }) => ($mt ? `margin-top: ${theme.size($mt)};` : "")};
  ${({ $mb, theme }) => ($mb ? `margin-bottom:  ${theme.size($mb)};` : "")};
`;

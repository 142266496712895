import { PropsWithChildren } from "react";
import { DataAttributes } from "types/ui";

import { DialogContainerProps } from "../DialogWindow";
import { Modal, ModalProps } from "../Modal";
import { StyledDialogModalContainer } from "./styles";

export interface DialogModalContainerProps {
  show: boolean;
  onClickOutside?: () => void;

  className?: string;
  dialog?: DialogContainerProps & DataAttributes;
  modal?: Omit<ModalProps, "onClick" | "show" | "xsCentered">;

  xsCentered?: boolean;
}

export function DialogModalContainer({
  show,
  children,
  dialog,
  modal,
  className,

  onClickOutside,
  xsCentered,
}: PropsWithChildren<DialogModalContainerProps>) {
  const {
    $width = "100%",
    $minHeight = "200px",
    $padding = "lg",
    $colorTheme = "modal",
    ...rest
  } = dialog || {};

  if (!show) {
    return null;
  }
  return (
    <Modal
      {...modal}
      xsCentered={xsCentered}
      show={show}
      onClick={onClickOutside}
    >
      <StyledDialogModalContainer
        className={className}
        {...rest}
        $width={$width}
        $minHeight={$minHeight}
        $padding={$padding}
        $colorTheme={$colorTheme}
      >
        {children}
      </StyledDialogModalContainer>
    </Modal>
  );
}

import { Col, Loader, Row, StyledButton } from "components/elements";
import { selectGearboxState } from "features/gearboxState/gearboxStateSlice";
import { useAppSelector } from "hooks";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { NewTxFormFormValues } from "types/transactions";

import { CmField } from "../NewTxFormFields/CmField";

export function ForbidBorrowingModule() {
  const { data, status } = useAppSelector(selectGearboxState);

  const {
    watch,
    formState: { errors },
  } = useFormContext<NewTxFormFormValues>();
  const watchedValue = watch();

  const disabled = useMemo(
    () => !watchedValue.cmd || !watchedValue.cm,
    [watchedValue.cmd, watchedValue.cm],
  );

  if (status !== "succeeded" || !data) {
    return (
      <Col $p={16}>
        <Loader />
      </Col>
    );
  }

  return (
    <>
      <CmField />

      <Row $noGutters $justify="flex-end">
        <StyledButton
          type="submit"
          disabled={disabled || Object.keys(errors).length > 0}
        >
          Add Tx
        </StyledButton>
      </Row>
    </>
  );
}

import { sleep } from "@gearbox-protocol/sdk-gov";
import { Col, Loader, Row, StyledButton } from "components/elements";
import { selectGearboxState } from "features/gearboxState/gearboxStateSlice";
import { useAppSelector } from "hooks";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import {
  NewTxFormFormValues,
  PoolInfo,
  SupportedTokenInfo,
} from "types/transactions";
import { getPoolTokenLimit } from "utils/state";

import { NumberField } from "../NewTxFormFields/NumberField";
import { PoolField } from "../NewTxFormFields/PoolField";
import { TokenField } from "../NewTxFormFields/TokenField";

export function SetTokenLimitModule() {
  const { data, status } = useAppSelector(selectGearboxState);

  const {
    watch,
    setValue,
    trigger,

    formState: { errors },
  } = useFormContext<NewTxFormFormValues>();
  const watchedValue = watch();

  const disabled = useMemo(
    () =>
      !watchedValue.cmd ||
      !watchedValue.pool ||
      !watchedValue.token ||
      watchedValue.limit === undefined,
    [
      watchedValue.pool,
      watchedValue.cmd,
      watchedValue.token,
      watchedValue.limit,
    ],
  );

  if (status !== "succeeded" || !data) {
    return (
      <Col $p={16}>
        <Loader />
      </Col>
    );
  }

  return (
    <>
      <PoolField
        onChangeCallback={async (pool: PoolInfo) => {
          const limit = getPoolTokenLimit({
            state: data,
            pool,
            token: watchedValue.token,
          });

          setValue("limit", limit);
          await sleep(1);
          await trigger("limit");
        }}
      />
      <TokenField
        onChangeCallback={async (token: SupportedTokenInfo) => {
          const limit = getPoolTokenLimit({
            state: data,
            pool: watchedValue.pool,
            token,
          });

          setValue("limit", limit);
          await sleep(1);
          await trigger("limit");
        }}
      />

      <NumberField
        name="limit"
        title="New Limit"
        validate={value => {
          const limit = getPoolTokenLimit({
            state: data,
            pool: watchedValue.pool,
            token: watchedValue.token,
          });

          if (limit !== undefined && limit === Number(value)) {
            return "New limit can not be the same";
          }

          return true;
        }}
      />

      <Row $noGutters $justify="flex-end">
        <StyledButton
          type="submit"
          disabled={disabled || Object.keys(errors).length > 0}
        >
          Add Tx
        </StyledButton>
      </Row>
    </>
  );
}

export enum Cmd {
  setTokenLimit = "setTokenLimit",
  addCollateralToken = "addCollateralToken",
  rampLiquidationThreshold = "rampLiquidationThreshold",
  setMinDebtLimit = "setMinDebtLimit",
  setMaxDebtLimit = "setMaxDebtLimit",
  setFees = "setFees",
  forbidBorrowing = "forbidBorrowing",
}

export interface CmdInfo {
  id: Cmd, 
  label: string, 
  cmd: string 
}
import { Col, Loader, Row, StyledButton } from "components/elements";
import { selectGearboxState } from "features/gearboxState/gearboxStateSlice";
import { useAppSelector } from "hooks";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { CmInfo, NewTxFormFormValues } from "types/transactions";
import { getCmFeeInfo } from "utils/state";

import { CmField } from "../NewTxFormFields/CmField";
import { NumberField } from "../NewTxFormFields/NumberField";

const validatePercent = (value?: number) => {
  if (value === undefined || value < 0 || value > 100) {
    return "Must be in range from 0 to 100";
  }

  const valueArray = value.toString().split(/[.,]/);

  if (valueArray.length >= 2 && valueArray[1].length > 2) {
    return "Must have at most 2 decimal places";
  }

  return true;
};

export function SetFeesModule() {
  const { data, status } = useAppSelector(selectGearboxState);

  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<NewTxFormFormValues>();

  const watchedValue = watch();

  const disabled = useMemo(
    () =>
      !watchedValue.cmd ||
      !watchedValue.cm ||
      !watchedValue.feeInterest ||
      !watchedValue.feeLiquidation ||
      !watchedValue.liquidationPremium ||
      !watchedValue.feeLiquidationExpired ||
      !watchedValue.liquidationPremiumExpired,
    [
      watchedValue.cmd,
      watchedValue.cm,
      watchedValue.feeInterest,
      watchedValue.feeLiquidation,
      watchedValue.liquidationPremium,
      watchedValue.feeLiquidationExpired,
      watchedValue.liquidationPremiumExpired,
    ],
  );

  if (status !== "succeeded" || !data) {
    return (
      <Col $p={16}>
        <Loader />
      </Col>
    );
  }

  return (
    <>
      <CmField
        onChangeCallback={(cm: CmInfo) => {
          const cmInfo = getCmFeeInfo({
            state: data,
            cm,
          });

          setValue("feeInterest", cmInfo?.feeInterest);
          setValue("feeLiquidation", cmInfo?.feeLiquidation);
          setValue("liquidationPremium", cmInfo?.liquidationPremium);
          setValue("feeLiquidationExpired", cmInfo?.feeLiquidationExpired);
          setValue(
            "liquidationPremiumExpired",
            cmInfo?.liquidationPremiumExpired,
          );
        }}
      />

      <NumberField
        name="feeInterest"
        title="Fee Interest"
        endingIcon="%"
        validate={validatePercent}
      />
      <NumberField
        name="feeLiquidation"
        title="Fee Liquidation"
        endingIcon="%"
        validate={validatePercent}
      />
      <NumberField
        name="liquidationPremium"
        title="Liquidation Premium"
        endingIcon="%"
        validate={validatePercent}
      />
      <NumberField
        name="feeLiquidationExpired"
        title="Fee Liquidation Expired"
        endingIcon="%"
        validate={validatePercent}
      />
      <NumberField
        name="liquidationPremiumExpired"
        title="Liquidation Premium Expired"
        endingIcon="%"
        validate={validatePercent}
      />

      <Row $noGutters $justify="flex-end">
        <StyledButton
          type="submit"
          disabled={disabled || Object.keys(errors).length > 0}
        >
          Add Tx
        </StyledButton>
      </Row>
    </>
  );
}

import { Col, StyledDropdown, Typography } from "components/elements";
import { selectGearboxState } from "features/gearboxState/gearboxStateSlice";
import { useAppSelector } from "hooks";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { FormFildProps } from "types/forms";
import { CmInfo, NewTxFormFormValues } from "types/transactions";

export function CmField({ onChangeCallback }: FormFildProps) {
  const { data } = useAppSelector(selectGearboxState);

  const { setValue, watch } = useFormContext<NewTxFormFormValues>();

  const watchedValue = watch();
  const selectedCm = useMemo(() => watchedValue.cm, [watchedValue.cm]);

  const cmList: CmInfo[] | undefined = useMemo(
    () =>
      data?.creditState.map(({ creditManager }) => ({
        label: creditManager.name,
        address: creditManager.address,
      })),
    [data],
  );

  if (!cmList) {
    return <></>;
  }

  return (
    <Col $noGutters $gap={2}>
      <Typography>Credit Manager</Typography>
      <StyledDropdown
        items={cmList}
        label={selectedCm?.label ?? "Select Credit Manager"}
        onSelect={async i => {
          setValue("cm", i);
          await onChangeCallback?.(i);
        }}
      />
    </Col>
  );
}

import { Cmd, CmdInfo } from "types/cmd";

export const TRANSACTION_SERVICE_URL: Record<number, string> = {
  1: "https://safe-transaction-mainnet.safe.global/",
  10: "https://safe-transaction-optimism.safe.global/",
  42161: "https://safe-transaction-arbitrum.safe.global/",
};

export const DELAY_BY_NETWORK: Record<number, number> = {
  1: 129600,
  10: 86400,
  42161: 86400,
};

export const TRANSACTION_CMD_LIST: Record<Cmd, CmdInfo> = {
  setTokenLimit: {
    id: Cmd.setTokenLimit,
    label: "Set Pool Token Limit",
    cmd: "pool.setTokenLimitHuman",
  },
  addCollateralToken: {
    id: Cmd.addCollateralToken,
    label: "Add CM Collateral Token",
    cmd: "credit.addCollateralTokenHuman",
  },
  rampLiquidationThreshold: {
    id: Cmd.rampLiquidationThreshold,
    label: "Ramp CM Liquidation Threshold",
    cmd: "credit.rampLiquidationThresholdHuman",
  },
  setMinDebtLimit: {
    id: Cmd.setMinDebtLimit,
    label: "Set CM Min Debt Limit",
    cmd: "credit.setMinDebtLimitHuman",
  },
  setMaxDebtLimit: {
    id: Cmd.setMaxDebtLimit,
    label: "Set CM Max Debt Limit",
    cmd: "credit.setMaxDebtLimitHuman",
  },
  setFees: {
    id: Cmd.setFees,
    label: "Set CM Fees",
    cmd: "credit.setFees",
  },
  forbidBorrowing: {
    id: Cmd.forbidBorrowing,
    label: "Forbid CM Borrowing",
    cmd: "credit.forbidBorrowing",
  },
};
